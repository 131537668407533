import React, { useState, useEffect } from 'react';
import {
  Popover,
  Menu,
  Form,
  Input,
  Button,
  Modal,
  DatePicker,
  message,
  Card,
  Row,
  Col,
  Tag,
  Avatar,
} from 'antd';
import { useHistory } from 'react-router';
import Line from '../../assets/images/line.svg';
import logo from '../../assets/images/logo.png';
import settings from '../../assets/icons/settings.svg';
import transactionIcon from '../../assets/icons/transaction.svg';
import deposit from '../../assets/icons/deposit.svg';
import withdraw from '../../assets/icons/wallet.svg';
import './index.css';
import api from '../../api';
import { useDataState, useTable } from '../../hooks';
import moment from 'moment';
import { getBanklogo } from '../../constants/bank';

function BankPage() {
  useEffect(() => {}, []);

  const [depositModalOpen, setDepositModalOpen] = useState();
  const [withdrawModalOpen, setWithdrawModalOpen] = useState();
  const [loading, setLoading] = useState();
  const userData = useDataState();
  const bankData = useDataState();

  const history = useHistory();
  const username = localStorage.getItem('username');

  useEffect(() => {
    const promise = api.user.getUserDetail(username);
    userData.load(Promise.resolve(promise).then((value) => value));
  }, []);

  useEffect(() => {
    const promise = api.user.getBank();
    bankData.load(Promise.resolve(promise).then((value) => value));
  }, []);

  const user = userData?.data?.data;
  const bank = bankData?.data?.data;

  const { tableProps } = useTable(() => {
    return api.user.getTransactionlList({ username });
  }, [loading]);

  const tabledata = tableProps?.dataSource?.data;

  const transactionList = tabledata?.map((transaction) => {
    const typeText = transaction.type === 'deposit' ? 'ฝากเงิน' : 'ถอนเงิน';
    const color =
      transaction.status === 'สำเร็จ'
        ? 'success'
        : transaction.status === 'ไม่สำเร็จ'
        ? 'error'
        : 'processing';
    const statusColor = transaction.type === 'deposit' ? '#06C755' : '#FF3232';
    const insertText = transaction.type === 'deposit' ? '+' : '-';
    return (
      <Card style={{ marginTop: '5px', fontWeight: ' bold', width: '50%' }}>
        <Row>
          <Col span={4}>
            <img src={transactionIcon} style={{ width: '70%' }}></img>
          </Col>
          <Col span={13}>
            <div style={{ color: '#ACACAC' }}>
              {moment(transaction.created_at).format('DD/MM/YYYY HH:mm')}
            </div>
            <div style={{ marginTop: '20px' }}>{typeText}</div>
          </Col>
          <Col span={7} align='right'>
            <Tag color={color}>{transaction.status}</Tag>
            <div
              style={{
                color: statusColor,
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >{`${insertText}${transaction.credits}฿`}</div>
          </Col>
        </Row>
      </Card>
    );
  });

  const showDepositModal = () => {
    setDepositModalOpen(true);
  };

  const closeDepositModal = () => {
    setDepositModalOpen(false);
  };
  const showWithdrawModal = () => {
    setWithdrawModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setWithdrawModalOpen(false);
  };

  const onDeposit = async (value) => {
    setLoading(true);
    await api.user
      .deposit(value, username)
      .then((res) => {
        message.success(<div style={{fontSize:'20px'}}>แจ้งฝากเงินสำเร็จ</div>);
        message.success(<div style={{fontSize:'20px'}}>แจ้งสลิปโอนเงิน ที่ Line เพื่อยืนยันการฝาก</div>);
        closeDepositModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onWithdraw = async (value) => {
    setLoading(true);
    await api.user
      .withdraw(value, username)
      .then((res) => {
        message.success('แจ้งถอนเงินสำเร็จ');
        closeWithdrawModal();
      })
      .catch((err) => {
        if (err.response.status === 501) {
          message.error('เครดิตไม่เพียงพอ');
        } else {
          message.error('เกิดข้อผิดพลาด');
        }
      });
    setLoading(false);
  };

  const depositModal = (
    <Modal
      open={depositModalOpen}
      onClose={closeDepositModal}
      onCancel={closeDepositModal}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '30px', textAlign: 'center' }}>ฝากเงิน</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onDeposit}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item
          label='ยอดเงิน'
          name='credits'
          required
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input type='number' placeholder='ยอดเงิน' />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeDepositModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
              disabled={loading}
            >
              ยืนยัน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const withdrawModal = (
    <Modal
      open={withdrawModalOpen}
      onClose={closeWithdrawModal}
      onCancel={closeWithdrawModal}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '30px', textAlign: 'center' }}>ถอนเงิน</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onWithdraw}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item
          label='ยอดเงิน'
          name='credits'
          required
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input type='number' placeholder='ยอดเงิน' />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeWithdrawModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249', color: 'black' }}
            >
              ยืนยัน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div>
      <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
        <div style={{ marginTop: '20px' }}>บัญชีธนาคาร</div>
        <Card
          style={{
            fontWeight: ' bold',
            width: '100%',
          }}
        >
          <Row>
            <Col span={3}>
              <Avatar src={getBanklogo(bank?.bank)} size={115}></Avatar>
            </Col>
            <Col span={14}>
              <div style={{ color: '#ACACAC' }}>{bank?.bank}</div>
              <div style={{ marginTop: '20px' }}>{bank?.bank_no}</div>
              <div
                style={{ marginTop: '20px' }}
              >{`${bank?.first_name} ${bank?.last_name}`}</div>
            </Col>
          </Row>
        </Card>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '20px',
        }}
      >
        {depositModal}
        {withdrawModal}
        <Button
          onClick={showDepositModal}
          style={{ width: '40%', fontFamily: 'Kanit' }}
          icon={
            <img
              src={deposit}
              style={{ height: '14px', marginRight: '5px' }}
            ></img>
          }
        >
          ฝากเงิน
        </Button>
        <Button
          onClick={showWithdrawModal}
          style={{ width: '40%', fontFamily: 'Kanit' }}
          icon={
            <img
              src={withdraw}
              style={{ height: '14px', marginRight: '5px' }}
            ></img>
          }
        >
          ถอนเงิน
        </Button>
      </div>
      <div style={{ paddingRight: '5%', paddingLeft: '5%', marginTop: '20px' }}>
        ประวัติการฝาก/ถอนล่าสุด
      </div>
      <Row style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        {transactionList}
      </Row>
    </div>
  );
}

export default BankPage;
