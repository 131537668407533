import bay from '../assets/bank/bay.png';
import bbl from '../assets/bank/bbl.png';
import cimb from '../assets/bank/cimb.png';
import citi from '../assets/bank/citi.png';
import gsb from '../assets/bank/gsb.png';
import kbank from '../assets/bank/kbank.png';
import ktb from '../assets/bank/ktb.png';
import scb from '../assets/bank/scb.png';
import tmb from '../assets/bank/tmb.png';
import uob from '../assets/bank/uob.png';
import baac from '../assets/bank/baac.png';
import bnp from '../assets/bank/bnp.png';
import boa from '../assets/bank/boa.png';
import cacib from '../assets/bank/cacib.png';
import db from '../assets/bank/db.png';
import ghb from '../assets/bank/ghb.png';
import hsbc from '../assets/bank/hsbc.png';
import ibank from '../assets/bank/ibank.png';
import icbc from '../assets/bank/icbc.png';
import jpm from '../assets/bank/jpm.png';
import kk from '../assets/bank/kk.png';
import lhb from '../assets/bank/lhb.png';
import mb from '../assets/bank/mb.png';
import mega from '../assets/bank/mega.png';
import mufg from '../assets/bank/mufg.png';
import promptpay from '../assets/bank/promptpay.png';
import rbs from '../assets/bank/rbs.png';
import sc from '../assets/bank/sc.png';
import smbc from '../assets/bank/smbc.png';
import tbank from '../assets/bank/tbank.png';
import tcrb from '../assets/bank/tcrb.png';
import tisco from '../assets/bank/tisco.png';
import ttb from '../assets/bank/ttb.png';

export const BANK = Object.freeze({
  KBANK: 'ธนาคารกสิกรไทย',
  krungsri: 'ธนาคารกรุงศรี',
  scb: 'ธนาคารไทยพาณิชย์',
  bangkok: 'ธนาคารกรุงเทพ',
  krungthai: 'ธนาคารกรุงไทย',
  tmb: 'ธนาคารทหารไทย',
  gsb: 'ธนาคารออมสิน',
  citi: 'ธนาคารซิตี้แบงก์',
  uob: 'ธนาคารยูโอบี',
  cimb: 'ธนาคาร ซีไอเอ็มบี ไทย',
  baac: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
  bnp: 'ธนาคารบีเอ็นพี',
  boa: 'Bank of America',
  cacib: 'Crédit Agricole',
  db: 'Deutsche Bank',
  ghb: 'ธนาคารอาคารสงเคราะห์',
  hsbc: 'HSBC',
  ibank: 'ธนาคารอิสลามแห่งประเทศไทย',
  icbc: 'ธนาคารไอซีบีซี (ไทย)',
  jpm: 'J.P. Morgan',
  kk: 'ธนาคารเกียรตินาคิน',
  lhb: 'L H Bank',
  mb: 'Mizuho Bank',
  mega: 'Mega Bank',
  mufg: 'Bank of Tokyo-Mitsubishi UFJ',
  promptpay: 'พร้อมเพย์',
  rbs: 'Royal Bank of Scotland',
  sc: 'Standard Chartered (Thai)',
  smbc: 'Sumitomo Mitsui Banking Corporation',
  tbank: 'ธนาคารธนชาติ',
  tcrb: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
  tisco: 'ธนาคารทิสโก้',
  ttb: 'ธนาคารทหารไทยธนชาต',
});

export const BANKOPTIONS = [
  {
    value: BANK.KBANK,
    label: BANK.KBANK,
  },
  {
    value: BANK.krungsri,
    label: BANK.krungsri,
  },
  {
    value: BANK.scb,
    label: BANK.scb,
  },
  {
    value: BANK.bangkok,
    label: BANK.bangkok,
  },
  {
    value: BANK.krungthai,
    label: BANK.krungthai,
  },
  {
    value: BANK.tmb,
    label: BANK.tmb,
  },
  {
    value: BANK.gsb,
    label: BANK.gsb,
  },
  {
    value: BANK.citi,
    label: BANK.citi,
  },
  {
    value: BANK.uob,
    label: BANK.uob,
  },
  {
    value: BANK.cimb,
    label: BANK.cimb,
  },
  {
    value: BANK.baac,
    label: BANK.baac,
  },
  {
    value: BANK.boa,
    label: BANK.boa,
  },
  {
    value: BANK.cacib,
    label: BANK.cacib,
  },
  {
    value: BANK.db,
    label: BANK.db,
  },
  {
    value: BANK.ghb,
    label: BANK.ghb,
  },
  {
    value: BANK.hsbc,
    label: BANK.hsbc,
  },
  {
    value: BANK.ibank,
    label: BANK.ibank,
  },
  {
    value: BANK.icbc,
    label: BANK.icbc,
  },
  {
    value: BANK.jpm,
    label: BANK.jpm,
  },
  {
    value: BANK.kk,
    label: BANK.kk,
  },
  {
    value: BANK.lhb,
    label: BANK.lhb,
  },
  {
    value: BANK.mb,
    label: BANK.mb,
  },
  {
    value: BANK.mega,
    label: BANK.mega,
  },
  {
    value: BANK.mufg,
    label: BANK.mufg,
  },
  {
    value: BANK.promptpay,
    label: BANK.promptpay,
  },
  {
    value: BANK.rbs,
    label: BANK.rbs,
  },
  {
    value: BANK.sc,
    label: BANK.sc,
  },
  {
    value: BANK.smbc,
    label: BANK.smbc,
  },
  {
    value: BANK.tbank,
    label: BANK.tbank,
  },
  {
    value: BANK.tcrb,
    label: BANK.tcrb,
  },
  {
    value: BANK.tisco,
    label: BANK.tisco,
  },
  {
    value: BANK.ttb,
    label: BANK.ttb,
  },
  {
    value: BANK.bnp,
    label: BANK.bnp,
  },
];

export const getBanklogo = (name) => {
  switch (name) {
    case BANK.KBANK:
      return kbank;
    case BANK.cimb:
      return cimb;
    case BANK.krungsri:
      return bay;
    case BANK.scb:
      return scb;
    case BANK.bangkok:
      return bbl;
    case BANK.krungthai:
      return ktb;
    case BANK.tmb:
      return tmb;
    case BANK.gsb:
      return gsb;
    case BANK.citi:
      return citi;
    case BANK.uob:
      return uob;
    case BANK.baac:
      return baac;
    case BANK.bnp:
      return bnp;
    case BANK.boa:
      return boa;
    case BANK.cacib:
      return cacib;
    case BANK.db:
      return db;
    case BANK.ghb:
      return ghb;
    case BANK.hsbc:
      return hsbc;
    case BANK.ibank:
      return ibank;
    case BANK.icbc:
      return icbc;
    case BANK.jpm:
      return jpm;
    case BANK.kk:
      return kk;
    case BANK.lhb:
      return lhb;
    case BANK.mb:
      return mb;
    case BANK.mega:
      return mega;
    case BANK.mufg:
      return mufg;
    case BANK.promptpay:
      return promptpay;
    case BANK.rbs:
      return rbs;
    case BANK.sc:
      return sc;
    case BANK.smbc:
      return smbc;
    case BANK.tbank:
      return tbank;
    case BANK.tcrb:
      return tcrb;
    case BANK.tisco:
      return tisco;
    case BANK.ttb:
      return ttb;
  }
};
