import axios from 'axios';

const api_path = process.env.REACT_APP_API;

const adminToken = localStorage.getItem('adminToken');

const headers = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + adminToken,
};

const errorHandler = (err) => {
  if (err?.response?.status === 401) {
    window.location.replace('/admin');
  }
};

const AdminApi = {
  login: (data) => {
    return axios.post(`${api_path}/adminlogin`, data).catch((err) => {
      errorHandler(err);
    });
  },

  getUserList: (keyword) => {
    return axios
      .post(`${api_path}/admin/user`, keyword, { headers })
      .catch((err) => errorHandler(err));
  },

  addUser: async (data) => {
    const response = await axios
      .post(`${api_path}/admin/user/create`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  addCredits: async (data, id) => {
    const response = await axios
      .post(`${api_path}/admin/addcredits/${id}`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  withdrawCredits: async (data, id) => {
    const response = await axios
      .post(`${api_path}/admin/withdrawcredits/${id}`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  editUser: async (payload, id) => {
    const response = await axios
      .post(`${api_path}/admin/edituser/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  editMaxBet: async (payload, id) => {
    const response = await axios
      .post(`${api_path}/admin/editMaxbet`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  getDefaultMaxBet: async (payload, id) => {
    const response = await axios
      .get(`${api_path}/admin/getDefaultMaxBet`, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  setDefaultMaxBet: async (payload, id) => {
    const response = await axios
      .post(`${api_path}/admin/setDefaultMaxBet`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  getUserDetail: async (payload) => {
    const response = await axios
      .post(`${api_path}/admin/userdetail`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  lockUser: async (id) => {
    const payload = { isLocked: 'true' };
    const response = await axios
      .post(`${api_path}/admin/edituser/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  unlockUser: async (id) => {
    const payload = { isLocked: 'false' };
    const response = await axios
      .post(`${api_path}/admin/edituser/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  lockAllUser: async () => {
    const response = await axios
      .post(`${api_path}/admin/lockAllUser`, {}, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  unlockAllUser: async () => {
    const response = await axios
      .post(`${api_path}/admin/unlockAllUser`, {}, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  changePassword: async (data, id) => {
    const response = await axios
      .post(`${api_path}/admin/user/changepassword/${id}`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  deleteUser: async (id) => {
    return axios
      .delete(`${api_path}/admin/user/${id}`, { headers })
      .catch((err) => {
        errorHandler(err);
      });
  },

  addBank: async (data) => {
    const response = await axios
      .post(`${api_path}/admin/bank/create`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  editBank: async (input, id) => {
    const payload = input;
    const response = await axios
      .post(`${api_path}/admin/editbank/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  getBankList: async (keyword) => {
    return axios
      .post(`${api_path}/bank`, keyword, { headers })
      .catch((err) => errorHandler(err));
  },

  lockBank: async (id) => {
    const payload = { isOpen: false };
    const response = await axios
      .post(`${api_path}/admin/editbank/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  unlockBank: async (id) => {
    const payload = { isOpen: true };
    const response = await axios
      .post(`${api_path}/admin/editbank/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  deleteBank: async (id) => {
    return axios
      .delete(`${api_path}/admin/bank/${id}`, { headers })
      .catch((err) => errorHandler(err));
  },

  getTransactionList: async (filter) => {
    return axios
      .post(`${api_path}/admin/transaction`, filter, { headers })
      .catch((err) => errorHandler(err));
  },

  getTransactionCount: async (filter) => {
    return axios
      .get(`${api_path}/admin/getTransactionCount`, { headers })
      .catch((err) => errorHandler(err));
  },

  editTransaction: async (input, id) => {
    const payload = input;
    const response = await axios
      .post(`${api_path}/admin/edittransaction/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  addHandicap: async (data) => {
    const response = await axios
      .post(`${api_path}/admin/handicap/create`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  getHandicapList: async (filter) => {
    return axios
      .post(`${api_path}/admin/handicap`, filter, { headers })
      .catch((err) => errorHandler(err));
  },

  announceHandicap: async (input, id) => {
    const payload = input;
    const response = await axios
      .post(`${api_path}/admin/announce/handicap/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  editHandicap: async (payload, id) => {
    const response = await axios
      .post(`${api_path}/admin/updatehandicap/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  lockHandicap: async (id) => {
    const payload = { is_active: false };
    const response = await axios
      .post(`${api_path}/admin/edithandicap/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  activateHandicap: async (id) => {
    const payload = { is_active: true };
    const response = await axios
      .post(`${api_path}/admin/edithandicap/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  closeHandicap: async (id) => {
    const payload = { is_open: false };
    const response = await axios
      .post(`${api_path}/admin/edithandicap/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  openHandicap: async (id) => {
    const payload = { is_open: true };
    const response = await axios
      .post(`${api_path}/admin/edithandicap/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  addStep: async (data) => {
    const response = await axios
      .post(`${api_path}/admin/step/create`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  getStepList: async (filter) => {
    return axios
      .post(`${api_path}/admin/step`, filter, { headers })
      .catch((err) => errorHandler(err));
  },

  lockStep: async (id) => {
    const payload = { is_active: false };
    const response = await axios
      .post(`${api_path}/admin/editstep/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  activateStep: async (id) => {
    const payload = { is_active: true };
    const response = await axios
      .post(`${api_path}/admin/editstep/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  closeStep: async (id) => {
    const payload = { is_open: false };
    const response = await axios
      .post(`${api_path}/admin/editstep/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  openStep: async (id) => {
    const payload = { is_open: true };
    const response = await axios
      .post(`${api_path}/admin/editstep/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  editStep: async (payload, id) => {
    const response = await axios
      .post(`${api_path}/admin/updatestep/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  announceStep: async (input, id) => {
    const payload = input;
    const response = await axios
      .post(`${api_path}/admin/announce/step`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  getBillList: async (filter) => {
    return axios
      .post(`${api_path}/admin/bill`, filter, { headers })
      .catch((err) => errorHandler(err));
  },

  getBillDetail: async (id) => {
    return axios
      .post(`${api_path}/admin/billDetail`, { id }, { headers })
      .catch((err) => errorHandler(err));
  },

  getSummary: async (filter) => {
    return axios
      .post(`${api_path}/admin/summary`, filter, { headers })
      .catch((err) => errorHandler(err));
  },

  addAdmin: async (data) => {
    const response = await axios
      .post(`${api_path}/admin/admin/create`, data, { headers })
      .catch((err) => {
        throw err;
      });

    return response;
  },

  getAdminList: (keyword) => {
    return axios
      .post(`${api_path}/admin/admin`, keyword, { headers })
      .catch((err) => errorHandler(err));
  },

  getAdminDetail: async (payload) => {
    const response = await axios
      .post(`${api_path}/admin/admindetail`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  editAdmin: async (payload, id) => {
    const response = await axios
      .post(`${api_path}/admin/editadmin/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  changePasswordAdmin: async (data, id) => {
    const response = await axios
      .post(`${api_path}/admin/admin/changepassword/${id}`, data, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  lockAdmin: async (id) => {
    const payload = { isLocked: 'true' };
    const response = await axios
      .post(`${api_path}/admin/editadmin/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },

  unlockAdmin: async (id) => {
    const payload = { isLocked: 'false' };
    const response = await axios
      .post(`${api_path}/admin/editadmin/${id}`, payload, { headers })
      .catch((err) => {
        errorHandler(err);
      });

    return response;
  },
};

export default AdminApi;
