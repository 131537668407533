import React, { useState, useEffect } from 'react';
import { Popover, Menu, Form, Input, Button, message, Modal } from 'antd';
import { useHistory } from 'react-router';
import Line from '../../assets/images/line.svg';
import settings from '../../assets/icons/settings.svg';
import report from '../../assets/icons/report.svg';
import wallet from '../../assets/icons/wallet.svg';
import hot from '../../assets/images/hot.png';
import logo from '../../assets/images/logo.png';
import bank from '../../assets/icons/bank.svg';
import user from '../../assets/icons/user.svg';
import dollar from '../../assets/icons/dollar.svg';
import football from '../../assets/icons/football.svg';
import { DownOutlined, PieChartOutlined } from '@ant-design/icons';
import './index.css';
import api from '../../api';
import { Redirect } from 'react-router';
import { useDataState } from '../../hooks';
import { getNumberDisplayWithCommaNFloating } from '../../utils/string';

function getItem(label, key, icon, path, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    path,
  };
}

function getPath(key) {
  const list = ['/step', '/handicap', '/bill', '/bankhistory'];
  return list[key];
}

const items = [
  getItem(
    <a href='https://ufa369auto.com/'>
      ufa369auto
      <img
        src={hot}
        style={{ width: '20px', marginLeft:'10px', transform: 'rotate(-45deg)' }}
      ></img>
    </a>,
    '4',
    null
  ),
  getItem('บอลสเต็ป', '0', null, '/step'),
  getItem('บอลเต็ง', '1', null, '/handicap'),
  getItem('ประวัติการเล่น', '2', null, '/bill'),
  getItem('ฝาก/ถอน', '3', null, '/deposit'),
];

function Layout({ children }) {
  const username = localStorage.getItem('username');
  const userData = useDataState();
  const [selectedKey, setSelectedKey] = useState(0);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState();

  const [resetPasswordform] = Form.useForm();
  const [loading, setLoading] = useState();

  useEffect(() => {
    const promise = api.user.getUserDetail(username);
    userData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  const onClick = (e) => {
    setLoading(false);
    setSelectedKey(e.key);
    history.push(getPath(e.key));
    setLoading(true);
  };

  const user = userData?.data?.data;

  const history = useHistory();

  const onLogout = () => {
    localStorage.clear();
    history.push('/');
  };

  const showChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const changePassword = async (value) => {
    setLoading(true);
    await api.user
      .changePassword({ password: value.password }, user._id)
      .then((res) => {
        message.success('เปลี่ยนรหัสผ่านสำเร็จ');
        closeChangePasswordModal();
        resetPasswordform.resetFields();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error('เกิดข้อผิดพลาด');
      });
  };

  const settingsContent = (
    <Menu>
      <Menu.Item onClick={showChangePasswordModal}>เปลี่ยนรหัสผ่าน</Menu.Item>
      <Menu.Item onClick={onLogout}>ออกจากระบบ</Menu.Item>
    </Menu>
  );

  const changePasswordModal = (
    <Modal
      open={changePasswordModalOpen}
      onClose={closeChangePasswordModal}
      onCancel={closeChangePasswordModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>เปลี่ยนรหัสผ่าน</div>
      {user && (
        <React.Fragment>
          <div>{`${user.first_name} ${user.last_name}`}</div>
          <div>{`${user.phone}`}</div>
        </React.Fragment>
      )}
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={changePassword}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
        form={resetPasswordform}
      >
        <Form.Item
          name='password'
          required
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input placeholder='รหัสผ่าน' />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeChangePasswordModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249' }}
            >
              เปลี่ยนรหัสผ่าน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div className='container' style={{ width: '1512px' }}>
      {changePasswordModal}
      <body>
        <header className='topbar'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img style={{ width: '170px' }} src={logo}></img>
            <div style={{ display: 'flex' }}>
              <div style={{ color: '#ba995d', margin: '5px' }}>
                <div>ยินดีต้อนรับ: {username}</div>
                <div>
                  เครดิต:{' '}
                  {getNumberDisplayWithCommaNFloating(user?.credits || 0)}
                </div>
              </div>

              <Popover content={settingsContent}>
                <img width='40px' src={settings}></img>
              </Popover>
            </div>
          </div>
          <Menu
            onClick={onClick}
            style={{ width: '100%', background: '#242424' }}
            theme='dark'
            selectedKeys={selectedKey}
            mode='horizontal'
            items={items}
          />
        </header>
        <div className='mobile-page-content'>{children}</div>
      </body>
    </div>
  );
}

export default Layout;
