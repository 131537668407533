import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useHistory } from 'react-router';
import Line from '../../assets/images/line.svg';
import logo from '../../assets/images/logo.png';
import wording from '../../assets/images/wording1.svg';
import './index.css';
import api from '../../api';

function LoginPage() {
  useEffect(() => { }, []);

  const history = useHistory();

  const onLogin = async (data) => {
    await api.user
      .login(data)
      .then((resp) => {
        if (resp.data) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('username', resp.data.username);
          history.push('/agreement');
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.error('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง');
        } else {
          message.error('เกิดข้อผิดพลาด');
        }
      });
  };

  return (
    <div className='container-login'>
      <body>
        <div
          style={{
            top: '20%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img style={{ width: '350px' }} src={logo}></img>
        </div>
        <div
          style={{
            top: '27%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img src={wording}></img>
        </div>

        <div className='loginForm'>
          <div className='center'>
            <h2 style={{ color: '#ba995d' }}>เข้าสู่ระบบ</h2>
          </div>
          <div className='center'>
            <Form layout='vertical' style={{ width: '80%' }} onFinish={onLogin}>
              <div style={{ color: '#ba995d' }}>ชื่อผู้ใช้งาน</div>
              <Form.Item
                name='username'
                style={{ width: '100%' }}
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder='ชื่อผู้ใช้งาน'
                ></Input>
              </Form.Item>
              <div style={{ color: '#ba995d' }}>รหัสผ่าน</div>
              <Form.Item
                type='password'
                name='password'
                style={{ width: '100%' }}
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input
                  type='password'
                  style={{ width: '100%' }}
                  placeholder='รหัสผ่าน'
                ></Input>
              </Form.Item>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  htmlType='submit'
                  className='loginButton'
                  style={{
                    width: '100%',
                    background: 'linear-gradient(to bottom, #e7d39d, #b19560)',
                  }}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div
            style={{
              top: '120%',
              left: '50%',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <a href='https://lin.ee/khfqy67'>
              <img style={{ width: '120px' }} src={Line}></img>
            </a>
          </div>
        </div>
      </body>
    </div>
  );
}

export default LoginPage;
