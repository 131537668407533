import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  message,
  Select,
  Avatar,
  Card,
  Row,
  Col,
  Progress,
  DatePicker,
} from 'antd';
import { useDataState, useTable } from '../../hooks';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import moment from 'moment';
import kbank from '../../assets/bank/kbank.svg';
import lock from '../../assets/icons/lock.svg';
import unlock from '../../assets/icons/unlock.svg';
import edit from '../../assets/icons/edit.svg';
import key from '../../assets/icons/key.svg';
import bin from '../../assets/icons/bin.svg';
import { getNumberDisplayWithCommaNFloating } from '../../utils/string';
import { BANKOPTIONS, getBanklogo } from '../../constants/bank';
import { useForm } from 'antd/es/form/Form';
import transactionIcon from '../../assets/icons/transaction.svg';

const { RangePicker } = DatePicker;
const { Search } = Input;

function AdminUserPage() {
  useEffect(() => {}, []);

  const bankOptions = BANKOPTIONS;

  const history = useHistory();

  const [form] = useForm();
  const [editForm] = useForm();
  const [withdrawForm] = useForm();
  const [depositForm] = useForm();
  const [changePasswordForm] = useForm();
  const [changeBetForm] = useForm();
  const [betSettingForm] = useForm();

  const [loading, setLoading] = useState();
  const [dateRange, setDateRange] = useState();
  const [search, setSearch] = useState();
  const [addModalOpen, setAddModalOpen] = useState();
  const [editModalOpen, setEditModalOpen] = useState();
  const [detailModalOpen, setDetailModalOpen] = useState();
  const [addCreditModalOpen, setAddCreditModalOpen] = useState();
  const [lockModalOpen, setLockModalOpen] = useState();
  const [unlockModalOpen, setUnlockModalOpen] = useState();
  const [lockAllModalOpen, setLockAllModalOpen] = useState();
  const [unlockAllModalOpen, setUnlockAllModalOpen] = useState();
  const [withdrawCreditModalOpen, setWithdrawCreditModalOpen] = useState();
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState();
  const [changeBetModalOpen, setChangeBetModalOpen] = useState();
  const [betSettingModalOpen, setBetSettingModalOpen] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const userData = useDataState();
  const adminData = useDataState();
  const maxBetData = useDataState();

  const admin = localStorage.getItem('admin');
  const role = localStorage.getItem('role');

  const canDeposit = role === 'admin ฝาก' || role === 'super admin';
  const canWithdraw = role === 'admin ถอน' || role === 'super admin';

  const { tableProps } = useTable(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    return api.admin.getUserList({
      keyword: search,
    });
  }, [loading, search]);

  useEffect(() => {
    const promise = api.admin.getAdminDetail({ username: admin });
    adminData.load(Promise.resolve(promise).then((value) => value));
  }, []);

  useEffect(() => {
    const promise = api.admin.getDefaultMaxBet();
    maxBetData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  const thisAdmin = adminData?.data?.data;

  const maxBetInfo = maxBetData?.data?.data;

  useEffect(() => {
    const payload = { ...selectedUser, date_filter: dateRange };
    console.log(payload);
    const promise = api.admin.getUserDetail(payload);
    userData.load(Promise.resolve(promise).then((value) => value));
  }, [loading, selectedUser, dateRange]);

  const userSummaryData = userData?.data?.data;
  const winData = userSummaryData?.winData || 0;
  const loseData = Math.abs(userSummaryData?.loseData || 0);
  const totalWinLoseData = winData + loseData;

  const onDateChange = (value) => {
    setDateRange(value);
  };

  const showAddModal = (value) => {
    setAddModalOpen(true);
    const generatedPassword = generatePassword();
    form.setFieldValue('password', generatedPassword);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const showEditModal = (value) => {
    setSelectedUser(value);
    editForm.setFieldsValue(value);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    editForm.resetFields();
  };

  const showChangeBetModal = (value) => {
    // setSelectedUser(value);
    changeBetForm.setFieldsValue(value);
    setChangeBetModalOpen(true);
  };

  const closeChangeBetModal = () => {
    setChangeBetModalOpen(false);
    changeBetForm.resetFields();
  };

  const showBetSettingModal = (value) => {
    setBetSettingModalOpen(true);
  };

  const closeBetSettingModal = () => {
    setBetSettingModalOpen(false);
    betSettingForm.resetFields();
  };

  const showDetailModal = (value) => {
    setSelectedUser(value);
    setDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setSelectedUser();
    setDateRange();
    setDetailModalOpen(false);
  };

  const showAddCreditModal = (user) => {
    setSelectedUser(user);
    setAddCreditModalOpen(true);
  };

  const closeAddCreditModal = () => {
    setAddCreditModalOpen(false);
  };

  const showWithdrawCreditModal = (user) => {
    setSelectedUser(user);
    setWithdrawCreditModalOpen(true);
  };

  const closeWithdrawCreditModal = () => {
    setWithdrawCreditModalOpen(false);
  };

  const showLockModal = (user) => {
    setSelectedUser(user);
    setLockModalOpen(true);
  };

  const closeLockModal = () => {
    setLockModalOpen(false);
  };

  const showUnlockModal = (user) => {
    setSelectedUser(user);
    setUnlockModalOpen(true);
  };

  const closeUnlockModal = () => {
    setUnlockModalOpen(false);
  };

  const showLockAllModal = () => {
    setLockAllModalOpen(true);
  };

  const closeLockAllModal = () => {
    setLockAllModalOpen(false);
  };

  const showUnlockAllModal = () => {
    setUnlockAllModalOpen(true);
  };

  const closeUnlockAllModal = () => {
    setUnlockAllModalOpen(false);
  };

  const showChangePasswordModal = (user) => {
    setSelectedUser(user);
    setChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const showDeleteModal = (user) => {
    setSelectedUser(user);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  function generatePassword() {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 8) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const onAddUser = async (value) => {
    setLoading(true);
    await api.admin
      .addUser(value)
      .then((res) => {
        message.success('เพิ่มสมาชิกสำเร็จ');
        closeAddModal();
        form.resetFields();
      })
      .catch((err) => {
        if (err.response.status === 501) {
          message.error('เลขที่บัญชีไม่ถูกต้อง');
        } else if (err.response.status === 502) {
          message.error('user มีผู้ใช้งานแล้ว');
        } else message.error('เกิดข้อผิดพลาด');
      });
    setLoading(false);
  };

  const onEditUser = async (value) => {
    setLoading(true);
    await api.admin
      .editUser(value, selectedUser._id)
      .then((res) => {
        message.success('แก้ไขข้อมูลสมาชิกสำเร็จ');
        setSelectedUser();
        closeEditModal();
        editForm.resetFields();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onChangeBetUser = async (value) => {
    setLoading(true);
    const payload = { userIds: [...selectedRowKeys], ...value };
    await api.admin
      .editMaxBet(payload)
      .then((res) => {
        message.success('แก้ไขยอดเดิมพันสำเร็จ');
        closeChangeBetModal();
        changeBetForm.resetFields();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onSetDefaultMaxBet = async (value) => {
    setLoading(true);
    const payload = { ...value };
    await api.admin
      .setDefaultMaxBet(payload)
      .then((res) => {
        message.success('ตั้งค่ายอดเดิมพันเริ่มต้นสำเร็จ');
        closeBetSettingModal();
        betSettingForm.resetFields();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onAddCredits = async (value) => {
    setLoading(true);
    await api.admin
      .addCredits({ value: value.credit, update_by: admin }, selectedUser._id)
      .then((res) => {
        message.success('เพิ่มเครดิตสำเร็จ');
        setSelectedUser();
        depositForm.resetFields();
        closeAddCreditModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onWithdrawCredits = async (value) => {
    setLoading(true);
    await api.admin
      .withdrawCredits(
        { value: value.credit, update_by: admin },
        selectedUser._id
      )
      .then((res) => {
        message.success('ถอนเครดิตสำเร็จ');
        setSelectedUser();
        withdrawForm.resetFields();
        closeWithdrawCreditModal();
      })
      .catch((err) => {
        if (err.response.status === 501) {
          message.error('เครดิตไม่เพียงพอ');
        } else message.error('เกิดข้อผิดพลาด');
      });
    setLoading(false);
  };

  const lockUser = async (value) => {
    setLoading(true);
    await api.admin
      .lockUser(selectedUser._id)
      .then((res) => {
        message.success('ระงับผู้ใช้งานสำเร็จ');
        closeLockModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const unlockUser = async () => {
    setLoading(true);
    await api.admin
      .unlockUser(selectedUser._id)
      .then((res) => {
        message.success('คืนสถานะผู้ใช้งานสำเร็จ');
        closeUnlockModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const lockAllUser = async () => {
    setLoading(true);
    await api.admin
      .lockAllUser()
      .then((res) => {
        message.success('ระงับผู้ใช้งานทั้งหมดสำเร็จ');
        closeLockAllModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const unlockAllUser = async () => {
    setLoading(true);
    await api.admin
      .unlockAllUser()
      .then((res) => {
        message.success('คืนสถานะผู้ใช้งานทั้งหมดสำเร็จ');
        closeUnlockAllModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const changePassword = async (value) => {
    setLoading(true);
    await api.admin
      .changePassword({ password: value.password }, selectedUser._id)
      .then((res) => {
        message.success('เปลี่ยนรหัสผ่านสำเร็จ');
        setSelectedUser();
        changePasswordForm.resetFields();
        closeChangePasswordModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const deleteUser = async () => {
    setLoading(true);
    await api.admin
      .deleteUser(selectedUser._id)
      .then((res) => {
        message.success('ลบผู้ใช้งานสำเร็จ');
        closeDeleteModal();
      })
      .catch((err) => {
        if (err.response.status === 501) {
          message.error('user มีบิลค้างในระบบ');
        } else {
          message.error('เกิดข้อผิดพลาด');
        }
      });
    setLoading(false);
  };

  const tabledata = tableProps?.dataSource?.data.map((value, index) => {
    return { key: value._id, ...value };
  });

  const onChange = (selectedRowKeys, selectedRows, type) => {
    if (type.type === 'single') {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };
  const onSelectAll = async (selected, selectedRows, changeRows) => {
    if (selected) {
      const selectedData = await tabledata?.map((data) => data);
      const selectedKeys = await tabledata?.map((data) => data.key);
      setSelectedRowKeys(selectedKeys);
      setSelectedRows(selectedData);
    } else {
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onChange,
    onSelectAll: onSelectAll,
  };

  const columns = [
    {
      title: 'รหัสผู้ใช้งาน',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.length - b.username.length,
      render: (_, row, index) => {
        return <a onClick={() => showDetailModal(row)}>{row.username}</a>;
      },
    },
    {
      title: 'ชื่อจริง-นามสกุล',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '170px',
      render: (_, row, index) => {
        return row ? row.first_name + ' ' + row.last_name : '-';
      },
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'บัญชีธนาคาร',
      dataIndex: 'bank_no',
      key: 'bank_no',
      render: (data, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            <Avatar src={getBanklogo(row.bank)} size={45}></Avatar>
            <p>{data}</p>
          </div>
        );
      },
    },
    {
      title: 'เครดิต',
      dataIndex: 'credits',
      key: 'credits',
      render: (data, row, index) => {
        return getNumberDisplayWithCommaNFloating(data);
      },
      sorter: (a, b) => a.credits - b.credits,
    },
    {
      title: 'วันเวลาที่สมัคร',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (data, row, index) => {
        moment(data).format('DD/MM/YYYY HH:mm');
        return moment(data).format('DD/MM/YYYY HH:mm');
      },
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: 'สถานะ',
      dataIndex: 'isLocked',
      key: 'isLocked',
      render: (_, row, index) => {
        return row.isLocked ? (
          <div style={{ color: '#FF3232' }}>ระงับการใช้งาน</div>
        ) : (
          <div style={{ color: '#06C755' }}>ปกติ</div>
        );
      },
    },
    {
      title: 'ถอน',
      dataIndex: 'credits',
      key: 'withdraw',
      render: (_, row, index) => {
        return (
          <Button
            onClick={() => showWithdrawCreditModal(row)}
            style={{ background: '#EA5455', color: '#FFFFFF' }}
            disabled={!canWithdraw}
          >
            ถอน
          </Button>
        );
      },
    },
    {
      title: 'เติม',
      dataIndex: 'credits',
      key: 'add',
      render: (_, row, index) => {
        return (
          <Button
            onClick={() => showAddCreditModal(row)}
            style={{ background: '#27AA85', color: '#FFFFFF' }}
            disabled={!canDeposit}
          >
            เติม
          </Button>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            <img onClick={() => showEditModal(row)} src={edit}></img>
            <img onClick={() => showChangePasswordModal(row)} src={key}></img>
            {row.isLocked ? (
              <img onClick={() => showUnlockModal(row)} src={unlock}></img>
            ) : (
              <img onClick={() => showLockModal(row)} src={lock}></img>
            )}
            <img onClick={() => showDeleteModal(row)} src={bin}></img>
          </div>
        );
      },
    },
  ];

  const addCreditModal = (
    <Modal
      open={addCreditModalOpen}
      onClose={closeAddCreditModal}
      onCancel={closeAddCreditModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>เติมเครดิต</div>
      {selectedUser && (
        <React.Fragment>
          <div>{`${selectedUser.username}`}</div>
          <div>{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
          <div>{`${selectedUser.bank} ${selectedUser.bank_no}`}</div>
          <div>{`เครดิตที่มี : ${selectedUser.credits}`}</div>
        </React.Fragment>
      )}
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onAddCredits}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
        form={depositForm}
      >
        <Form.Item
          name='credit'
          required
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input placeholder='เครดิต' type='number' />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAddCreditModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#27AA85' }}
            >
              เติมเครดิต
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const withdrawCreditModal = (
    <Modal
      open={withdrawCreditModalOpen}
      onClose={closeWithdrawCreditModal}
      onCancel={closeWithdrawCreditModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ถอนเครดิต</div>
      {selectedUser && (
        <React.Fragment>
          <div>{`${selectedUser.username}`}</div>
          <div>{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
          <div>{`${selectedUser.bank} ${selectedUser.bank_no}`}</div>
          <div>{`เครดิตที่มี : ${selectedUser.credits}`}</div>
        </React.Fragment>
      )}
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onWithdrawCredits}
        form={withdrawForm}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item
          name='credit'
          required
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input placeholder='เครดิต' type='number' />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeWithdrawCreditModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#CE0E02' }}
            >
              ถอนเครดิต
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const detailModal = (
    <Modal
      open={detailModalOpen}
      onClose={closeDetailModal}
      onCancel={closeDetailModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ข้อมูลสมาชิก</div>
      {selectedUser && (
        <React.Fragment>
          <div>{`Username: ${selectedUser.username}`}</div>
          <div>{`ชื่อ-นามสกุล: ${selectedUser.first_name} ${selectedUser.last_name}`}</div>
          <div>{`เบอร์โทร: ${selectedUser.phone}`}</div>
          <div>{`ยอดเดิมพันบอลเต็งสูงสุด: ${selectedUser.handicap_max_bet}`}</div>
          <div>{`ยอดจ่ายบอลสเต็ปสูงสุด: ${selectedUser.step_max_paid}`}</div>
        </React.Fragment>
      )}
      <div style={{ fontSize: '20px', marginTop: '20px' }}>ข้อมูลบัญชี</div>
      <RangePicker onChange={onDateChange}></RangePicker>
      <Card>
        <Row>
          <Col span={3}>
            {selectedUser && (
              <Avatar src={getBanklogo(selectedUser?.bank)} size={40} />
            )}
          </Col>
          <Col span={14}>
            <div>{selectedUser?.bank_no}</div>
            <div>{`${selectedUser?.first_name} ${selectedUser?.last_name}`}</div>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row>
          <Col span={3}>
            {selectedUser && (
              <img src={transactionIcon} style={{ width: '90%' }}></img>
            )}
          </Col>
          <Col span={14}>
            <div style={{ fontWeight: 'bold' }}>ยอดฝากทั้งหมด</div>
            <div>{`฿${getNumberDisplayWithCommaNFloating(
              userSummaryData?.depositData || 0
            )}`}</div>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row>
          <Col span={3}>
            {selectedUser && (
              <img src={transactionIcon} style={{ width: '90%' }}></img>
            )}
          </Col>
          <Col span={14}>
            <div style={{ fontWeight: 'bold' }}>ยอดถอนทั้งหมด</div>
            <div>{`฿${getNumberDisplayWithCommaNFloating(
              userSummaryData?.withdrawData || 0
            )}`}</div>
          </Col>
        </Row>
      </Card>
      <div style={{ fontSize: '20px', marginTop: '20px' }}>
        อัตราการเดิมพันทั้งหมด
      </div>
      <Row>
        <Col>
          <Progress
            percent={getNumberDisplayWithCommaNFloating(
              (winData * 100) / totalWinLoseData
            )}
            type='circle'
            strokeColor={'#F7D249'}
          ></Progress>
        </Col>
        <Col style={{ color: '#4F5E7485', marginLeft: '10px' }}>
          <div
            style={{ marginTop: '30px' }}
          >{`ชนะ ฿${getNumberDisplayWithCommaNFloating(winData)}`}</div>
          <div>{`แพ้ ฿${getNumberDisplayWithCommaNFloating(loseData)}`}</div>
        </Col>
      </Row>
    </Modal>
  );

  const lockModal = (
    <Modal
      open={lockModalOpen}
      onClose={closeLockModal}
      onCancel={closeLockModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ระงับการใช้งาน</div>
      {selectedUser && (
        <React.Fragment>
          <div>{`${selectedUser.username}`}</div>
          <div>{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
          <div>{`${selectedUser.phone}`}</div>
        </React.Fragment>
      )}
      <div style={{ fontSize: '20px' }}>คุณต้องการระงับการใช้งานหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={lockUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeLockModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#CE0E02' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const unlockModal = (
    <Modal
      open={unlockModalOpen}
      onClose={closeUnlockModal}
      onCancel={closeUnlockModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>คืนสถานะผู้ใช้งาน</div>
      {selectedUser && (
        <React.Fragment>
          <div>{`${selectedUser.username}`}</div>
          <div>{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
          <div>{`${selectedUser.phone}`}</div>
        </React.Fragment>
      )}
      <div style={{ fontSize: '20px' }}>คุณต้องการคืนสถานะผู้ใช้งานหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={unlockUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeLockModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              disabled={loading}
              htmlType='submit'
              style={{ background: '#27AA85' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const lockAllModal = (
    <Modal
      open={lockAllModalOpen}
      onClose={closeLockAllModal}
      onCancel={closeLockAllModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ระงับการใช้งานทั้งหมด</div>
      <div style={{ fontSize: '20px' }}>
        คุณต้องการระงับการใช้งานทั้งหมดหรือไม่
      </div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={lockAllUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeLockAllModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#CE0E02' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const unlockAllModal = (
    <Modal
      open={unlockAllModalOpen}
      onClose={closeUnlockAllModal}
      onCancel={closeUnlockAllModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>คืนสถานะผู้ใช้งานทั้งหมด</div>
      <div style={{ fontSize: '20px' }}>
        คุณต้องการคืนสถานะผู้ใช้งานทั้งหมดหรือไม่
      </div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={unlockAllUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeUnlockAllModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              disabled={loading}
              htmlType='submit'
              style={{ background: '#27AA85' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const changePasswordModal = (
    <Modal
      open={changePasswordModalOpen}
      onClose={closeChangePasswordModal}
      onCancel={closeChangePasswordModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>เปลี่ยนรหัสผ่าน</div>
      {selectedUser && (
        <React.Fragment>
          <div>{`${selectedUser.username}`}</div>
          <div>{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
          <div>{`${selectedUser.phone}`}</div>
        </React.Fragment>
      )}
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={changePassword}
        layout='vertical'
        form={changePasswordForm}
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item name='password' required>
          <Input placeholder='รหัสผ่าน' />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeChangePasswordModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249' }}
            >
              เปลี่ยนรหัสผ่าน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const addModal = (
    <Modal
      open={addModalOpen}
      onClose={closeAddModal}
      onCancel={closeAddModal}
      style={{ fontFamily: 'Kanit', width: '500px' }}
      width='1000px'
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>เพิ่มข้อมูลสมาชิก</div>
      <Form
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onAddUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%', fontSize: '20px' }}>ข้อมูลทั่วไป</div>
          <Form.Item
            label='ชื่อจริง'
            name='first_name'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='ชื่อจริง' />
          </Form.Item>
          <Form.Item
            label='นามสกุล'
            name='last_name'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='นามสกุล' />
          </Form.Item>
          <Form.Item
            label='เบอร์โทรศัพท์'
            name='phone'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='เบอร์โทรศัพท์' />
          </Form.Item>
          <Form.Item
            label='เลขสมาชิก เว็บ ufa369auto'
            name='user_id'
            style={{ width: '48%' }}
          >
            <Input placeholder='User ID' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%', fontSize: '20px' }}>
            ข้อมูลบัญชีธนาคาร
          </div>
          <Form.Item
            label='เลือกธนาคาร'
            name='bank'
            required
            style={{ width: '48%' }}
          >
            <Select placeholder='เลือกธนาคาร' options={bankOptions}></Select>
          </Form.Item>
          <Form.Item
            label='เลขบัญชีธนาคาร'
            name='bank_no'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='เลขบัญชีธนาคาร' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%', fontSize: '20px' }}>
            สร้างบัญชีผู้ใช้งาน
          </div>
          <Form.Item
            label='ชื่อผู้ใช้งาน'
            name='username'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='ชื่อผู้ใช้งาน' />
          </Form.Item>
          <Form.Item
            label='รหัสผ่าน'
            name='password'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='รหัสผ่าน' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAddModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
              disabled={loading}
            >
              เพิ่ม
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const changeBetModal = (
    <Modal
      open={changeBetModalOpen}
      onClose={closeChangeBetModal}
      onCancel={closeChangeBetModal}
      style={{ fontFamily: 'Kanit', width: '500px' }}
      width='1000px'
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>แก้ไขยอดเดิมพัน</div>
      <Form
        form={changeBetForm}
        onFinish={onChangeBetUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <p>จำนวน User แก้ไข : {selectedRowKeys.length}</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item
            label='ยอดเดิมพันบอลเต็ง'
            name='handicap_max_bet'
            style={{ width: '48%' }}
          >
            <Input placeholder='ยอดเดิมพันบอลเต็ง' type='number' />
          </Form.Item>
          <Form.Item
            label='ยอดจ่ายบอลสเต็ป'
            name='step_max_paid'
            style={{ width: '48%' }}
          >
            <Input placeholder='ยอดจ่ายบอลสเต็ป' type='number' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeChangeBetModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249', color: 'black' }}
            >
              แก้ไข
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const betSettingModal = (
    <Modal
      open={betSettingModalOpen}
      onClose={closeBetSettingModal}
      onCancel={closeBetSettingModal}
      style={{ fontFamily: 'Kanit', width: '500px' }}
      width='1000px'
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>แก้ไขยอดเดิมพัน</div>
      <div style={{color:"red"}}>*ค่าที่แก้ไขจะมีผลกับuser ที่สร้างใหม่เท่านั้น</div>
      <Form
        form={betSettingForm}
        onFinish={onSetDefaultMaxBet}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <p>ยอดเดิมพันบอลเต็งสูงสุดล่าสุด: {maxBetInfo?.handicap_max_bet}</p>
        <p>ยอดจ่ายบอลสเต็ปสูงสุดล่าสุด: {maxBetInfo?.step_max_paid}</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item
            label='ยอดเดิมพันบอลเต็ง'
            name='handicap_max_bet'
            style={{ width: '48%' }}
          >
            <Input placeholder='ยอดเดิมพันบอลเต็ง' type='number' />
          </Form.Item>
          <Form.Item
            label='ยอดจ่ายบอลสเต็ป'
            name='step_max_paid'
            style={{ width: '48%' }}
          >
            <Input placeholder='ยอดจ่ายบอลสเต็ป' type='number' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeBetSettingModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249', color: 'black' }}
            >
              แก้ไข
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const editModal = (
    <Modal
      open={editModalOpen}
      onClose={closeEditModal}
      onCancel={closeEditModal}
      style={{ fontFamily: 'Kanit', width: '500px' }}
      width='1000px'
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>แก้ไขข้อมูลสมาชิก</div>
      <Form
        initialValues={selectedUser}
        form={editForm}
        onFinish={onEditUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%', fontSize: '20px' }}>ข้อมูลทั่วไป</div>
          <Form.Item
            label='ชื่อจริง'
            name='first_name'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='ชื่อจริง' />
          </Form.Item>
          <Form.Item
            label='นามสกุล'
            name='last_name'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='นามสกุล' />
          </Form.Item>
          <Form.Item
            label='เบอร์โทรศัพท์'
            name='phone'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='เบอร์โทรศัพท์' />
          </Form.Item>
          <Form.Item
            label='เลขสมาชิก เว็บ ufa369auto'
            name='user_id'
            style={{ width: '48%' }}
          >
            <Input placeholder='User ID' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%', fontSize: '20px' }}>
            ข้อมูลบัญชีธนาคาร
          </div>
          <Form.Item
            label='เลือกธนาคาร'
            name='bank'
            required
            style={{ width: '48%' }}
          >
            <Select placeholder='เลือกธนาคาร' options={bankOptions}></Select>
          </Form.Item>
          <Form.Item
            label='เลขบัญชีธนาคาร'
            name='bank_no'
            required
            style={{ width: '48%' }}
          >
            <Input placeholder='เลขบัญชีธนาคาร' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAddModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249', color: 'black' }}
            >
              แก้ไข
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const deleteModal = (
    <Modal
      open={deleteModalOpen}
      onClose={closeDeleteModal}
      onCancel={closeDeleteModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ลบผู้ใช้งาน</div>
      {selectedUser && (
        <React.Fragment>
          <div>{`${selectedUser.username}`}</div>
          <div>{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
          <div>{`${selectedUser.phone}`}</div>
        </React.Fragment>
      )}
      <div style={{ fontSize: '20px' }}>คุณต้องการลบผู้ใช้งานหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={deleteUser}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeDeleteModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              disabled={loading}
              htmlType='submit'
              style={{ background: '#27AA85' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div>
      {addModal}
      {editModal}
      {addCreditModal}
      {withdrawCreditModal}
      {lockModal}
      {unlockModal}
      {changePasswordModal}
      {detailModal}
      {deleteModal}
      {lockAllModal}
      {unlockAllModal}
      {changeBetModal}
      {betSettingModal}
      <div style={{ fontSize: '30px' }}>สมาชิก</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Search
          onSearch={setSearch}
          placeholder='ค้นหาชื่อสมาชิก, รหัสสมาชิก'
          style={{ width: '30%' }}
        ></Search>
        <div style={{ display: 'flex' }}>
          {thisAdmin?.can_edit_bet && (
            <Button
              placeholder=''
              type='primary'
              style={{
                marginLeft: '10px',
              }}
              onClick={showBetSettingModal}
            >
              ตั้งค่าราคาเดิมพันเริ่มต้น
            </Button>
          )}
          {thisAdmin?.can_edit_bet && (
            <Button
              placeholder=''
              type='primary'
              style={{
                marginLeft: '10px',
              }}
              disabled={selectedRowKeys.length === 0}
              onClick={showChangeBetModal}
            >
              ปรับราคาเดิมพัน
            </Button>
          )}
          <Button
            placeholder=''
            style={{
              background: '#EA5455',
              marginLeft: '10px',
              color: 'white',
            }}
            onClick={showLockAllModal}
          >
            ระงับทั้งหมด
          </Button>
          <Button
            placeholder=''
            style={{
              background: '#27AA85',
              marginLeft: '10px',
              color: 'white',
            }}
            onClick={showUnlockAllModal}
          >
            เปิดใช้ทั้งหมด
          </Button>
          <Button
            placeholder=''
            style={{ background: '#F7D249', marginLeft: '10px' }}
            onClick={showAddModal}
          >
            + เพิ่มสมาชิก
          </Button>
        </div>
      </div>
      <p>เลือกแล้ว : {selectedRowKeys.length} รายการ</p>
      <Table
        dataSource={tabledata}
        rowSelection={rowSelection}
        columns={columns}
      ></Table>
    </div>
  );
}

export default AdminUserPage;
