import useDataState from './useDataState';

const useList = (initialValue, initialKey) => {
  const source = useDataState(initialValue, initialKey);

  return {
    load: source.load,
    loading: source.loading,
    key: source.dataKey,
    list: source.data,
    total: source.data?.length || 0,
    get: index => source.data[index],
    set: (index, data) => {
      source.setData(source.data.map((item, i) => (i === index ? data : item)));
    },
    remove: index => {
      source.setData(source.data.filter((item, i) => i !== index));
    }
  };
};

export default useList;
