import { useEffect, useState } from 'react';
import useList from './useList';
import useSequence from './useSequence';

const useTable = (getData, dependencies, { pageSize = 10 } = {}) => {
  const source = useList();
  const [page, setPage] = useState();
  const seq = useSequence();

  const total = source.list?.length || 0;

  useEffect(() => {
    source.load(getData(), seq.getAndIncrease());
  }, dependencies);

  useEffect(() => {
    if (source.key !== undefined) {
      setPage(1);
    }
  }, [source.key]);

  useEffect(() => {
    if (page > 1 && total <= pageSize * (page - 1)) {
      setPage(page - 1);
    }
  }, [page]);

  return {
    source,
    tableProps: {
      loading: source.loading,
      dataSource: source.list,
      pagination: {
        current: page,
        onChange: setPage,
        pageSize,
        total
      }
    }
  };
};

export default useTable;
