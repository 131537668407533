import { useState, useRef, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

export default function useDataState(initialData, initialDataKey) {
  const [data, setData] = useState(initialData);
  const [dataKey, setDataKey] = useState(initialDataKey);
  // assume that data must be in loading state at first render
  // if initialData is null/undefined
  const [loading, setLoading] = useState(
    initialData === null || initialData === undefined
  );

  const loadingKey = useRef();

  const load = useCallback(
    (dataPromise, dataPromiseKey) => {
      loadingKey.current = dataPromiseKey;
      if (!loading) {
        setLoading(true);
      }
      dataPromise.then(data => {
        // loadingKey may change when multiple load are called
        // we set data only for the last call
        if (loadingKey.current === dataPromiseKey) {
          unstable_batchedUpdates(() => {
            setData(data);
            setDataKey(dataPromiseKey);
            setLoading(false);
          });
        }
      });
    },
    [setData, setDataKey, setLoading, loadingKey, loading]
  );

  return {
    load, // trigger loading state and set data when promise is resolved
    loading, // whether new data is being loaded
    loadingKey: loadingKey.current, // key of being loaded data
    data, // current data
    dataKey, // key of current data
    setData: (data, key) => {
      unstable_batchedUpdates(() => {
        setData(data);
        setDataKey(key);
      });
    } // set data wihtout involving loading state
  };
}
