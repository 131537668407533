import React, { useState, useEffect } from 'react';
import {
  Popover,
  Menu,
  Form,
  Input,
  Table,
  Card,
  Button,
  Row,
  Col,
  message,
  Checkbox,
  Popconfirm,
  Collapse,
  Modal,
} from 'antd';
import Line from '../../assets/images/line.svg';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import { useDataState, useTable } from '../../hooks';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import { DownOutlined } from '@ant-design/icons';
import { getShowHandicapScore } from '../../constants/handicap';
const { Panel } = Collapse;

function HandicapPage() {
  const history = useHistory();
  const username = localStorage.getItem('username');
  const [selectedMatch, setSelectedMatch] = useState();
  const [loading, setLoading] = useState();
  const [showStep, setShowStep] = useState();
  const [value, setValue] = useState();
  const [form] = useForm();
  const [confirmModalOpen, setConfirmModalOpen] = useState();

  const userData = useDataState();
  const billData = useDataState();
  const stepBillData = useDataState();

  // const { table }
  const { Column, ColumnGroup } = Table;
  const { tableProps } = useTable(() => {
    return api.user.getHandicapList({
      date: moment(),
    });
  }, [loading]);

  useEffect(() => {
    const promise = api.user.getUserDetail(username);
    userData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  useEffect(() => {
    const promise = api.user.getHandicapBill({ username });
    billData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  useEffect(() => {
    const promise = api.user.getStepBill({ username });
    stepBillData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  const tabledata = tableProps?.dataSource?.data;
  const bill = billData?.data?.data;
  const stepBill = stepBillData?.data?.data;
  const user = userData?.data?.data;
  const maxBetValue = user?.handicap_max_bet

  function getItem(label, key, icon, path, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
      path,
    };
  }
  function getPath(key) {
    const list = ['/handicap', '/step'];
    return list[key];
  }

  const onSelectMenu = (e) => {
    history.push(getPath(e.key));
  };

  const items = [
    getItem('แสดงเมนูกีฬา', 'sub1', '', '', [
      getItem(
        'ฟุตบอล',
        'g1',
        '',
        '',
        [
          getItem('ราคาต่อรอง', '0', '', '/handicap'),
          getItem('มิกซ์ พาร์เลย์', '1', '', '/step'),
        ],
        'group'
      ),
    ]),
  ];

  const onCancel = () => {
    closeConfirmModal();
    setSelectedMatch();
    setValue();
    form.setFieldValue('bet_credits', '');
  };

  const showConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const onBet = async () => {
    setLoading(true);
    // let text = 'เดิมพันยืนยัน?';
    // if (window.confirm(text) == true) {
    const payload = {
      ...selectedMatch,
      username: username,
      bet_credits: value,
    };
    if (selectedMatch.is_open != true && user?.isSpecial != true) {
      message.error('คู่นี้มีผู้เดิมพันจำนวนมาก กรุณาลองใหม่อีกครั้ง');
    } else {
      await api.user
        .betHandicap(payload)
        .then((res) => {
          message.success('ทำรายการสำเร็จ');
          closeConfirmModal();
          onCancel();
          window.location.reload();
        })
        .catch((err) => {
          if (err.response.status === 501) {
            message.error('เดิมพัน 1 วัน/บิล');
          } else if (err.response.status === 502) {
            message.error(
              'มีคู่บอลบางคู่มีผู้เดิมพันจำนวนมาก กรุณาลองใหม่อีกครั้ง'
            );
          } else if (err.response.status === 400) {
            message.error('เครดิตไม่เพียงพอ');
          } else message.error('เกิดข้อผิดพลาด');
        });
    }
    setLoading(false);
    // } else {
    // }
  };

  const isSelectedTeamHandicap =
    selectedMatch?.selectedTeam === selectedMatch?.handicap_team;

  const confirmModal = (
    <Modal
      open={confirmModalOpen}
      onClose={closeConfirmModal}
      onCancel={closeConfirmModal}
      style={{ fontFamily: 'Kanit' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>เดิมพันยืนยัน?</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={onBet}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeConfirmModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249', color: 'black' }}
            >
              ยืนยัน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div className='container'>
      {confirmModal}
      <Row style={{ height: '100%' }}>
        <Col span={4} style={{ padding: '10px', background: '#B5B5B5' }}>
          {!selectedMatch && (
            <div>
              <Menu
                onClick={onSelectMenu}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode='inline'
                theme='dark'
                items={items}
              />
              <Collapse
                style={{
                  background: '#393939',
                  borderRadius: '0px',
                  marginTop: '5px',
                }}
              >
                <Panel
                  header={
                    <div style={{ color: 'white', fontWeight: 'bold' }}>
                      รอ และ ยกเลิก
                    </div>
                  }
                  key='1'
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '-13px',
                      marginRight: '-13px',
                    }}
                  >
                    <Button
                      style={{
                        width: '45%',
                        background:
                          'linear-gradient(to bottom, #FAFAFA, #DEDEDE)',
                      }}
                    >
                      ที่รอ
                    </Button>
                    <Button
                      style={{
                        width: '45%',
                        background:
                          'linear-gradient(to bottom, #FAFAFA, #DEDEDE)',
                      }}
                    >
                      โมฆะ
                    </Button>
                  </div>
                  {bill && bill?.selected_matches && (
                    <Card
                      style={{
                        border: '1px solid #8c8c8c',
                        borderRadius: '0px',
                        marginTop: '10px',
                        background: '#e4e4e4',
                        fontSize: '10px',
                        marginLeft: '-13px',
                        marginRight: '-13px',
                      }}
                      className='padding'
                    >
                      <div>{`#${bill?.bill_number} (${moment(
                        bill?.bet_at
                      ).format('DD/MM HH:mm')})`}</div>
                      <div>{`${bill?.selected_matches[0].match_id.team_a} -vs- ${bill?.selected_matches[0].match_id.team_b}`}</div>
                      <div
                        style={{
                          fontWeight: 'bold',
                          color:
                            bill?.selected_matches[0].handicap_team ===
                            bill?.selected_matches[0].selected_team
                              ? '#FF3232'
                              : '',
                        }}
                      >
                        {bill?.selected_matches[0].selected_team}
                        <span
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                            marginLeft: '15px',
                          }}
                        >
                          {`(${
                            bill?.selected_matches[0].handicap_team ===
                            bill?.selected_matches[0].selected_team
                              ? '-'
                              : ''
                          }${bill?.selected_matches[0].handicap_score}) 2.0`}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ background: 'green', color: 'white' }}>
                          เดิมพันสำเร็จ
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          {bill?.bet_credits}
                        </div>
                      </div>
                    </Card>
                  )}
                  {stepBill && stepBill?.selected_matches && (
                    <Card
                      style={{
                        border: '1px solid #8c8c8c',
                        borderRadius: '0px',
                        marginTop: '10px',
                        background: '#e4e4e4',
                        fontSize: '10px',
                        marginLeft: '-13px',
                        marginRight: '-13px',
                      }}
                      className='padding'
                    >
                      <div>{`#${stepBill?.bill_number} (${moment(
                        stepBill?.bet_at
                      ).format('DD/MM HH:mm')})`}</div>
                      <div
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>มิกซ์ พาร์เลย์</div>
                        <DownOutlined onClick={() => setShowStep(!showStep)} />
                      </div>
                      {showStep &&
                        stepBill?.selected_matches.map((match) => {
                          return (
                            <div
                              style={{
                                border: '1px solid #c0c0c0',
                                marginLeft: '5px',
                                marginBottom: '5px',
                              }}
                            >
                              <div>{`${match.match_id.team_a} -vs- ${match.match_id.team_b}`}</div>
                              <div>
                                {moment(match.match_id.match_start_at).format(
                                  'HH:mm'
                                )}
                              </div>
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  color:
                                    match.handicap_team === match.selected_team
                                      ? '#FF3232'
                                      : '',
                                }}
                              >
                                {match.selected_team}
                                <span
                                  style={{ color: 'black', marginLeft: '10px' }}
                                >
                                  {' '}
                                  {`(${
                                    match.handicap_team === match.selected_team
                                      ? '-'
                                      : ''
                                  }${match.handicap_score}) 2.0`}
                                </span>
                              </div>
                              <div>แต้มต่อ</div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    background: 'green',
                                    color: 'white',
                                  }}
                                >
                                  เดิมพันสำเร็จ
                                </div>
                                <div></div>
                              </div>
                            </div>
                          );
                        })}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ background: 'green', color: 'white' }}>
                          เดิมพันสำเร็จ
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          {stepBill?.bet_credits}
                        </div>
                      </div>
                    </Card>
                  )}
                </Panel>
              </Collapse>
            </div>
          )}
          {selectedMatch && (
            <Card style={{ background: '#e0e4f0', borderRadius: '0px' }}>
              <div style={{ fontWeight: 'bold' }}>ฟุตบอล / แต้มต่อ</div>
              <Card
                style={{ border: '1px solid #8c8c8c', borderRadius: '0px' }}
              >
                <div
                  style={{
                    color: isSelectedTeamHandicap ? '#FF3232' : 'black',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {`${selectedMatch?.selectedTeam}`}
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: ' #606060',
                  }}
                >
                  {`แฮนดิแคป ${isSelectedTeamHandicap ? '-' : ''}${
                    selectedMatch?.handicap_score
                  }`}
                </div>
              </Card>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <div>{selectedMatch?.team_a}</div>
                <div>-vs-</div>
                <div>{selectedMatch?.team_b}</div>
              </div>

              <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={showConfirmModal}
                style={{ fontFamily: 'Kanit' }}
                form={form}
              >
                <Form.Item
                  label='จำนวนเงิน : '
                  name='bet_credits'
                  rules={[
                    { required: true, message: 'กรุณากรอกจำนวนเงิน' },
                    {
                      validator: (rule, value) => {
                        if (user?.isSpecial) {
                          return Promise.resolve();
                        }
                        if (value < 10) {
                          return Promise.reject('เดิมพันขั้นต่ำ 10 บาท');
                        }
                        if (value > maxBetValue) {
                          return Promise.reject(`เดิมพันได้สูงสุด ${maxBetValue} บาท`);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type='number'
                    onChange={(e) => setValue(e.target.value)}
                  ></Input>
                </Form.Item>
                <Checkbox
                  defaultChecked
                  style={{
                    fontSize: '11px',
                    background: '#C9C9D3',
                    width: '100%',
                    marginBottom: '15px',
                  }}
                >
                  ยอมรับราคาต่อรองที่ดีกว่า
                </Checkbox>
                <Row>
                  <Col span={12} style={{ padding: '1px' }}>
                    <div style={{ fontSize: '10px', background: '#C9C9D3' }}>
                      การจ่ายเงินขั้นสูงสุด :{' '}
                    </div>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      fontSize: '10px',
                      padding: '1px',
                      textAlign: 'right',
                    }}
                  >
                    <div style={{ background: 'white' }}>
                      {value > maxBetValue ? maxBetValue : value}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ padding: '1px' }}>
                    <div style={{ fontSize: '10px', background: '#C9C9D3' }}>
                      เดิมพันขั้นต่ำสุด :{' '}
                    </div>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      color: '#003399',
                      fontSize: '10px',
                      padding: '1px',
                      textAlign: 'right',
                    }}
                  >
                    <div style={{ background: 'white' }}>10</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ padding: '1px' }}>
                    <div style={{ fontSize: '10px', background: '#C9C9D3' }}>
                      เดิมพันขั้นสูงสุด :
                    </div>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      color: '#FF3232',
                      fontSize: '10px',
                      padding: '1px',
                      textAlign: 'right',
                    }}
                  >
                    <div style={{ background: 'white' }}>{maxBetValue}</div>
                  </Col>
                </Row>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                  }}
                >
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{ color: 'white' }}
                    >
                      ทำรายการ
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={onCancel}>ยกเลิก</Button>
                  </Form.Item>
                </div>
              </Form>
            </Card>
          )}
        </Col>
        <Col
          span={20}
          align='center'
          style={{ padding: '10px', background: '#E5E5E5' }}
        >
          <Table
            dataSource={tabledata}
            pagination={false}
            style={{ margin: 'auto' }}
          >
            <Column
              title='เวลา'
              dataIndex='match_start_at'
              key='match_start_at'
              render={(data, row, index) => {
                moment(data).format('DD/MM/YYYY HH:mm');
                return moment(data).format('DD/MM/YYYY HH:mm');
              }}
            />
            <Column
              title='คู่แข่งขัน'
              dataIndex='team_a'
              key='team_a'
              align='center'
              render={(data, row, index) => {
                return (
                  <div>
                    <p
                      style={{
                        color:
                          row.team_a === row.handicap_team ? '#FF3232' : '',
                      }}
                    >
                      {row.team_a}
                    </p>
                    <p
                      style={{
                        color:
                          row.team_b === row.handicap_team ? '#FF3232' : '',
                      }}
                    >
                      {row.team_b}
                    </p>
                  </div>
                );
              }}
            />
            <ColumnGroup title='เต็มเวลา'>
              <Column
                title='ราคาต่อรอง'
                dataIndex='handicap_score'
                key='handicap_score'
                align='center'
                render={(data, row, index) => {
                  return getShowHandicapScore(data);
                }}
              />
              <Column
                title='ทีมเจ้าบ้าน'
                dataIndex='team_a'
                key='team_a'
                align='center'
                render={(data, row, index) => {
                  return (
                    <a
                      onClick={() => {
                        setSelectedMatch({ ...row, selectedTeam: row.team_a });
                      }}
                      className='link'
                    >
                      2.00
                    </a>
                  );
                }}
              />
              <Column
                title='ทีมเยือน'
                dataIndex='team_b'
                key='team_b'
                align='center'
                render={(data, row, index) => {
                  return (
                    <a
                      onClick={() => {
                        setSelectedMatch({ ...row, selectedTeam: row.team_b });
                      }}
                      className='link'
                    >
                      2.00
                    </a>
                  );
                }}
              />
            </ColumnGroup>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default HandicapPage;
