import { useRef } from 'react';

const useSequence = (initialValue = 0) => {
  const seq = useRef(initialValue);

  return {
    getAndIncrease: () => {
      const current = seq.current;
      seq.current += 1;
      return current;
    }
  };
};

export default useSequence;
