import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Menu,
  DatePicker,
  Progress,
  Card,
  Row,
  Col,
  Badge,
  Tag,
} from 'antd';
import { useHistory } from 'react-router';
import Line from '../../assets/images/line.svg';
import logo from '../../assets/images/logo.png';
import football from '../../assets/icons/football.svg';
import user from '../../assets/icons/user.svg';
import './index.css';
import api from '../../api';
import {
  AppstoreOutlined,
  ContainerOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useDataState } from '../../hooks';
import { getNumberDisplayWithCommaNFloating } from '../../utils/string';

const { RangePicker } = DatePicker;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('ภาพรวม', '1', <PieChartOutlined />),
  getItem('Option 2', '2'),
  getItem('Option 3', '3', <img src={football}></img>),
  getItem('Option 3', '3', <img src={football}></img>),
];

function AdminDashboardPage({ history }) {
  const [date, setDate] = useState([moment(), moment()]);
  // const history = useHistory();
  const betData = useDataState();

  useEffect(() => {
    const promise = api.admin.getSummary({ date: date });
    betData.load(Promise.resolve(promise).then((value) => value));
  }, [date]);

  const handicapBetCount = betData?.data?.data.handicapBetData || 0;
  const stepBetCount = betData?.data?.data.stepBetData || 0;
  const totalBetCount = handicapBetCount + stepBetCount;

  const depositData = betData?.data?.data.depositData || 0;
  const withdrawData = betData?.data?.data.withdrawData || 0;
  const stepBillCount = betData?.data?.data.stepBillCount || 0;
  const totalTransaciton = depositData + withdrawData;

  const handicap = betData?.data?.data.handicapData;
  const onDateChange = (value) => {
    setDate(value || [moment(), moment()]);
  };

  const handicapList = handicap?.map((value) => {
    var teamAIndex = 0;
    var teamBIndex = 0;
    if (value.selected_team_data) {
      teamAIndex =
        value.team_a === value?.selected_team_data[0].selected_team ? 0 : 1;
      teamBIndex =
        value.team_b === value?.selected_team_data[0].selected_team ? 0 : 1;
    }

    return (
      <Col span={8} style={{ padding: '15px' }}>
        <Badge.Ribbon
          color='#000000'
          placement='start'
          text={moment(value.match_start_at).format('HH:mm')}
        >
          <Card style={{ width: '100%', fontWeight: 'bold' }}>
            <Row style={{ marginTop: '20px' }}>
              <Col span={8} align='center' style={{ color: '#FF3232' }}>
                {value.team_a}
              </Col>
              <Col span={8} align='center'>
                <Tag>
                  {value.score_a ? `${value.score_a} - ${value.score_b}` : '-'}
                </Tag>
              </Col>
              <Col span={8} align='center'>
                {value.team_b}
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col span={8} align='center'>
                <Tag icon={<img src={user} style={{ width: '12px' }} />}>
                  {value.selected_team_data &&
                  value.selected_team_data[teamAIndex]
                    ? value.selected_team_data[teamAIndex].bill_count
                    : 0}
                </Tag>
              </Col>
              <Col span={8} align='center'>
                <Tag>{value.handicap_score}</Tag>
              </Col>
              <Col span={8} align='center'>
                <Tag icon={<img src={user} style={{ width: '12px' }} />}>
                  {value.selected_team_data &&
                  value.selected_team_data[teamBIndex]
                    ? value.selected_team_data[teamBIndex].bill_count
                    : 0}
                </Tag>
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col span={8} align='center'>
                {value.selected_team_data &&
                value.selected_team_data[teamAIndex]
                  ? `฿${getNumberDisplayWithCommaNFloating(
                      value.selected_team_data[teamAIndex].bet_credits_sum
                    )}`
                  : `฿${getNumberDisplayWithCommaNFloating(0)}`}
              </Col>
              <Col span={8} align='center'></Col>
              <Col span={8} align='center'>
                {value.selected_team_data &&
                value.selected_team_data[teamBIndex]
                  ? `฿${getNumberDisplayWithCommaNFloating(
                      value.selected_team_data[teamBIndex].bet_credits_sum
                    )}`
                  : `฿${getNumberDisplayWithCommaNFloating(0)}`}
              </Col>
            </Row>
          </Card>
        </Badge.Ribbon>
      </Col>
    );
  });

  return (
    <div>
      <div style={{ fontSize: '30px' }}>ภาพรวม</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        {date?.length > 0 ? (
          <div>{`วันที่ ${date[0].format('DD/MM/YYYY')} - ${date[1].format(
            'DD/MM/YYYY'
          )}`}</div>
        ) : (
          <div>วันที่ {moment().format('DD/MM/YYYY')}</div>
        )}
        <RangePicker onChange={onDateChange}></RangePicker>
      </div>
      <Row
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Col span={8}>
          <Card style={{ width: '90%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Progress
                percent={getNumberDisplayWithCommaNFloating(
                  (handicapBetCount * 100) / totalBetCount
                )}
                type='circle'
                strokeColor={'#92DD9E'}
              ></Progress>
              <div style={{ margin: 'auto', fontFamily: 'Kanit' }}>
                <div style={{ fontSize: '18px', color: '#6C6C6C' }}>
                  ยอดบอลเต็ง
                </div>
                <div
                  style={{ fontSize: '18px' }}
                >{`฿${getNumberDisplayWithCommaNFloating(
                  handicapBetCount
                )}`}</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: '90%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Progress
                percent={getNumberDisplayWithCommaNFloating(
                  (stepBetCount * 100) / totalBetCount
                )}
                type='circle'
                strokeColor={'#EE6E73'}
              ></Progress>
              <div style={{ margin: 'auto', fontFamily: 'Kanit' }}>
                <div style={{ fontSize: '18px', color: '#6C6C6C' }}>
                  ยอดบอลสเต็ป
                </div>
                <div
                  style={{ fontSize: '18px' }}
                >{`฿${getNumberDisplayWithCommaNFloating(stepBetCount)}`}</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: '90%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Progress
                percent={100}
                type='circle'
                strokeColor={'#F7D249'}
              ></Progress>
              <div style={{ margin: 'auto', fontFamily: 'Kanit' }}>
                <div style={{ fontSize: '18px', color: '#6C6C6C' }}>
                  ยอดเล่นทั้งหมด
                </div>
                <div
                  style={{ fontSize: '18px' }}
                >{`฿${getNumberDisplayWithCommaNFloating(totalBetCount)}`}</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: '90%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Progress
                percent={getNumberDisplayWithCommaNFloating(
                  (depositData * 100) / totalTransaciton
                )}
                type='circle'
                strokeColor={'#92DD9E'}
              ></Progress>
              <div style={{ margin: 'auto', fontFamily: 'Kanit' }}>
                <div style={{ fontSize: '18px', color: '#6C6C6C' }}>ยอดฝาก</div>
                <div
                  style={{ fontSize: '18px' }}
                >{`฿${getNumberDisplayWithCommaNFloating(depositData)}`}</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: '90%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Progress
                percent={getNumberDisplayWithCommaNFloating(
                  (withdrawData * 100) / totalTransaciton
                )}
                type='circle'
                strokeColor={'#EE6E73'}
              ></Progress>
              <div style={{ margin: 'auto', fontFamily: 'Kanit' }}>
                <div style={{ fontSize: '18px', color: '#6C6C6C' }}>ยอดถอน</div>
                <div
                  style={{ fontSize: '18px' }}
                >{`฿${getNumberDisplayWithCommaNFloating(withdrawData)}`}</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: '90%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Progress
                  percent={100}
                  type='circle'
                  strokeColor={'#F7D249'}
              ></Progress>
              <div style={{ margin: 'auto', fontFamily: 'Kanit' }}>
                <div style={{ fontSize: '18px', color: '#6C6C6C' }}>จำนวนบิลสเต็ป</div>
                <div
                  style={{ fontSize: '18px' }}
                >{stepBillCount}</div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <div>สถิติการเล่นบอล</div>
      <Row>{handicapList}</Row>
    </div>
  );
}

export default AdminDashboardPage;
