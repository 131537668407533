import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  message,
  Select,
  Space,
  DatePicker,
  Switch,
  Row,
  Col,
  Checkbox,
} from 'antd';
import { useTable } from '../../hooks';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import moment from 'moment';
import kbank from '../../assets/bank/kbank.svg';
import lock from '../../assets/icons/lock.svg';
import announce from '../../assets/icons/announce.svg';
import edit from '../../assets/icons/edit.svg';
import unlock from '../../assets/icons/unlock.svg';
import key from '../../assets/icons/key.svg';

import { getNumberDisplayWithCommaNFloating } from '../../utils/string';

const { Search } = Input;
const { RangePicker } = DatePicker;

function AdminHandicapPage() {
  useEffect(() => {}, []);

  const history = useHistory();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [announceForm] = Form.useForm();
  const admin = localStorage.getItem('admin');

  const [loading, setLoading] = useState();
  const [search, setSearch] = useState();
  const [date, setDate] = useState([moment(), moment()]);
  const [addModalOpen, setAddModalOpen] = useState();
  const [editModalOpen, setEditModalOpen] = useState();
  const [announceModalOpen, setAnnounceModalOpen] = useState();
  const [confirmModalOpen, setConfirmModalOpen] = useState();
  const [secondConfirmModalOpen, setSecondConfirmModalOpen] = useState();
  const [scorePayload, setScorePayload] = useState();

  const [selectedHandicap, setSelectedHandicap] = useState();
  const [toggleStatusModalOpen, setToggleStatusModalOpen] = useState();
  const [toggleOpenModalOpen, setToggleOpenModalOpen] = useState();

  const role = localStorage.getItem('role');
  const canEdit = role === 'admin ball' ||  role === 'super admin'

  const { tableProps } = useTable(() => {
    return api.admin.getHandicapList({
      keyword: search,
      date: date,
    });
  }, [search, loading, date]);

  const onDateChange = (value) => {
    setDate(value || [moment(), moment()]);
  };

  const showToggleStatusModal = (handicap) => {
    setSelectedHandicap(handicap);
    setToggleStatusModalOpen(true);
  };

  const closeToggleStatusModal = () => {
    setToggleStatusModalOpen(false);
  };

  const showToggleOpenModal = (handicap) => {
    setSelectedHandicap(handicap);
    setToggleOpenModalOpen(true);
  };

  const closeToggleOpenModal = () => {
    setToggleOpenModalOpen(false);
  };

  const showAddModal = (value) => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const showAnnounceModal = (user) => {
    setSelectedHandicap(user);
    setAnnounceModalOpen(true);
  };

  const closeAnnounceModal = () => {
    setAnnounceModalOpen(false);
    announceForm.resetFields();
  };

  const showEditModal = (handicap) => {
    setEditModalOpen(true);
    setSelectedHandicap(handicap);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    editForm.resetFields();
    setSelectedHandicap();
  };

  const editHandicap = async (value) => {
    setLoading(true);
    await api.admin
      .editHandicap(value, selectedHandicap._id)
      .then((res) => {
        message.success('แก้ไขสำเร็จ');
        closeEditModal();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.error('เกิดข้อผิดพลาดก่อนแข่งขัน');
        } else {
          message.error('เกิดข้อผิดพลาด');
        }
      });
    setLoading(false);
  };

  const lockHandicap = async () => {
    setLoading(true);
    await api.admin
      .lockHandicap(selectedHandicap._id)
      .then((res) => {
        message.success('เปลี่ยนสถานะสำเร็จ');
        closeToggleStatusModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const activateHandicap = async () => {
    setLoading(true);
    await api.admin
      .activateHandicap(selectedHandicap._id)
      .then((res) => {
        message.success('เปลี่ยนสถานะสำเร็จ');
        closeToggleStatusModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const closeHandicap = async () => {
    setLoading(true);
    await api.admin
      .closeHandicap(selectedHandicap._id)
      .then((res) => {
        message.success('เปลี่ยนสถานะการเดิมพันสำเร็จ');
        closeToggleOpenModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const openHandicap = async () => {
    setLoading(true);
    await api.admin
      .openHandicap(selectedHandicap._id)
      .then((res) => {
        message.success('เปลี่ยนสถานะการเดิมพันสำเร็จ');
        closeToggleOpenModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onAddHandicap = async (value) => {
    setLoading(true);
    await api.admin
      .addHandicap(value.match)
      .then((res) => {
        message.success('เพิ่มคู่บอลสำเร็จ');
        form.resetFields();
        closeAddModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onAnnounce = async () => {
    setLoading(true);
    await api.admin
      .announceHandicap(scorePayload, selectedHandicap._id)
      .then((res) => {
        message.success('ประกาศผลสำเร็จ');
        closeAnnounceModal();
        setConfirmModalOpen(false);
        setSecondConfirmModalOpen(false);
        announceForm.resetFields();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const tabledata = tableProps?.dataSource?.data;

  const columns = [
    {
      title: 'เวลา',
      dataIndex: 'match_start_at',
      key: 'match_start_at',
      render: (data, row, index) => {
        moment(data).format('DD/MM/YYYY HH:mm');
        return moment(data).format('DD/MM/YYYY HH:mm');
      },
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: 'ทีมเจ้าบ้าน',
      dataIndex: 'team_a',
      key: 'team_a',
      render: (data, row, index) => {
        const isHandicap = data === row.handicap_team;
        return <p style={{ color: isHandicap ? '#FF3232' : '' }}>{data}</p>;
      },
    },
    {
      title: 'ทีมเยือน',
      dataIndex: 'team_b',
      key: 'team_b',
      render: (data, row, index) => {
        const isHandicap = data === row.handicap_team;
        return <p style={{ color: isHandicap ? '#FF3232' : '' }}>{data}</p>;
      },
    },
    {
      title: 'แต้มต่อ',
      dataIndex: 'handicap_score',
      key: 'handicap_score',
      render: (data, row, index) => {
        return <p>{data}</p>;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (data, row, index) => {
        return (
          <Switch
            onClick={() => showToggleStatusModal(row)}
            checked={data}
            disabled={row.score_a >= 0}
          ></Switch>
        );
      },
    },
    {
      title: 'การเดิมพัน',
      dataIndex: 'is_open',
      key: 'is_open',
      render: (data, row, index) => {
        return (
          <Switch
            onClick={() => showToggleOpenModal(row)}
            checked={data}
            disabled={row.score_a >= 0}
          ></Switch>
        );
      },
    },
    {
      title: 'ผลการแข่งขัน',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (data, row, index) => {
        if (row.is_cancelled) {
          return 'ยกเลิก';
        }
        if (!(row.score_a >= 0)) {
          return 'รอประกาศผล';
        }
        return `${row.score_a} - ${row.score_b}`;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            {!(row.score_a >= 0) && canEdit  && (
              <React.Fragment>
                <img
                  style={{ width: '30px', marginRight: '10px' }}
                  src={edit}
                  onClick={() => showEditModal(row)}
                ></img>
                <img
                  style={{ width: '30px' }}
                  onClick={() => showAnnounceModal(row)}
                  src={announce}
                ></img>
              </React.Fragment>
            )}
          </div>
        );
      },
    },
  ];

  const confirmModal = (
    <Modal
      width={1000}
      open={confirmModalOpen}
      onClose={() => setConfirmModalOpen(false)}
      onCancel={() => setConfirmModalOpen(false)}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      {announceForm.getFieldValue('is_cancelled') ? (
        <div>ยืนยันยกเลิกการแข่งขัน</div>
      ) : (
        <div>คุณต้องการประกาศผลหรือไม่</div>
      )}
      <Row style={{ margin: '20px' }}>
        <Col span={4}>
          {moment(selectedHandicap?.match_start_at).format('HH:mm')}
        </Col>
        <Col span={10} align='right'>
          <div
            style={{
              color:
                selectedHandicap?.team_a === selectedHandicap?.handicap_team
                  ? '#FF3232'
                  : '',
            }}
          >
            {`${selectedHandicap?.team_a} ${scorePayload?.score_a}`}
          </div>
        </Col>
        <Col span={10} align='right'>
          <div
            style={{
              color:
                selectedHandicap?.team_b === selectedHandicap?.handicap_team
                  ? '#FF3232'
                  : '',
            }}
          >{`${selectedHandicap?.team_b} ${scorePayload?.score_b}`}</div>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setConfirmModalOpen(false)}>ยกเลิก</Button>
        <Button
          type='primary'
          cf
          onClick={() => setSecondConfirmModalOpen(true)}
          style={{ background: '#2D2D2D' }}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );

  const secondConfirmModal = (
    <Modal
      open={secondConfirmModalOpen}
      width={1000}
      onClose={() => setSecondConfirmModalOpen(false)}
      onCancel={() => setSecondConfirmModalOpen(false)}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      {announceForm.getFieldValue('is_cancelled') ? (
        <div>ยืนยันยกเลิกการแข่งขัน</div>
      ) : (
        <div>คุณต้องการประกาศผลหรือไม่</div>
      )}
      <div style={{ color: '#FF3232' }}>
        *หากยืนยันไปแล้วจะไม่สามารถแก้ไขได้
      </div>
      <Row style={{ margin: '20px' }}>
        <Col span={4}>
          {moment(selectedHandicap?.match_start_at).format('HH:mm')}
        </Col>
        <Col span={10} align='right'>
          <div
            style={{
              color:
                selectedHandicap?.team_a === selectedHandicap?.handicap_team
                  ? '#FF3232'
                  : '',
            }}
          >
            {`${selectedHandicap?.team_a} ${scorePayload?.score_a}`}
          </div>
        </Col>
        <Col span={10} align='right'>
          <div
            style={{
              color:
                selectedHandicap?.team_b === selectedHandicap?.handicap_team
                  ? '#FF3232'
                  : '',
            }}
          >{`${selectedHandicap?.team_b} ${scorePayload?.score_b}`}</div>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setSecondConfirmModalOpen(false)}>ยกเลิก</Button>
        <Button
          type='primary'
          disabled={loading}
          onClick={onAnnounce}
          style={{ background: '#2D2D2D' }}
        >
          ประกาศผล
        </Button>
      </div>
    </Modal>
  );

  const announceModal = (
    <Modal
      open={announceModalOpen}
      onClose={closeAnnounceModal}
      onCancel={closeAnnounceModal}
      style={{ fontFamily: 'Kanit' }}
      width={1000}
      footer={null}
    >
      <Form
        initialValues={{
          remember: true,
        }}
        form={announceForm}
        onFinish={(value) => {
          setScorePayload(value);
          setConfirmModalOpen(true);
        }}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div style={{ fontSize: '20px', marginBottom: ' 20px' }}>
          ประกาศผลบอลเต็ง รอบวันที่{' '}
          {moment(selectedHandicap?.match_start_at).format('DD/MM/YYYY')}
        </div>
        {selectedHandicap && (
          <div>
            <Row>
              <Col span={4}>
                {moment(selectedHandicap?.match_start_at).format('HH:mm')}
              </Col>
              <Col span={5} align='right'>
                <div
                  style={{
                    color:
                      selectedHandicap?.team_a ===
                      selectedHandicap?.handicap_team
                        ? '#FF3232'
                        : '',
                  }}
                >
                  {selectedHandicap.team_a}
                </div>
              </Col>
              <Col span={5}>
                <Form.Item
                  name='score_a'
                  rules={[{ required: true, message: 'Missing value' }]}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
              <Col span={5} align='right'>
                <div
                  style={{
                    color:
                      selectedHandicap?.team_b ===
                      selectedHandicap?.handicap_team
                        ? '#FF3232'
                        : '',
                  }}
                >
                  {selectedHandicap.team_b}
                </div>
              </Col>
              <Col span={5}>
                <Form.Item
                  name='score_b'
                  rules={[{ required: true, message: 'Missing value' }]}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name='is_cancelled' valuePropName='checked'>
              <Checkbox>ยกเลิก</Checkbox>
            </Form.Item>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAnnounceModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#2D2D2D' }}
              disabled={loading}
            >
              ประกาศผล
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const toggleStatusModal = (
    <Modal
      open={toggleStatusModalOpen}
      onClose={closeToggleStatusModal}
      onCancel={closeToggleStatusModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div>คุณต้องการเปลี่ยนสถานะหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={selectedHandicap?.is_active ? lockHandicap : activateHandicap}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeToggleStatusModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
              disabled={loading}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const toggleOpenModal = (
    <Modal
      open={toggleOpenModalOpen}
      onClose={closeToggleOpenModal}
      onCancel={closeToggleOpenModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div>คุณต้องการเปลี่ยนสถานะการเดิมพันหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={selectedHandicap?.is_open ? closeHandicap : openHandicap}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeToggleOpenModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
              disabled={loading}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const addModal = (
    <Modal
      open={addModalOpen}
      onClose={closeAddModal}
      onCancel={closeAddModal}
      style={{ fontFamily: 'Kanit', width: '500px' }}
      width='1200px'
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>เพิ่มคู่บอล</div>
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={onAddHandicap}
        style={{ fontFamily: 'Kanit' }}
        layout='vertical'
      >
        <Form.List name='match'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                    position: 'relative',
                  }}
                  align='baseline'
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'match_start_at']}
                    rules={[
                      { required: true, message: 'Missing value' },
                      {
                        validator: (rule, value) => {
                          if (value < moment().add(10, 'minute')) {
                            return Promise.reject('กรอกเวลาไม่ถูกต้อง');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    label='เวลาแข่ง'
                  >
                    <DatePicker
                      showTime
                      format='YYYY-MM-DD HH:mm'
                      placeholder='--:--'
                    ></DatePicker>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'team_a']}
                    rules={[{ required: true, message: 'Missing value' }]}
                    label='ทีมเจ้าบ้าน'
                  >
                    <Input placeholder='กรอกชื่อทีม' />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'team_b']}
                    rules={[{ required: true, message: 'Missing value' }]}
                    label='ทีมเยือน'
                  >
                    <Input placeholder='กรอกชื่อทีม' />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'handicap_team']}
                    rules={[{ required: true, message: 'Missing value' }]}
                    label='ทีมต่อ'
                  >
                    <Input placeholder='กรอกชื่อทีม' />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'handicap_score']}
                    rules={[{ required: true, message: 'Missing value' }]}
                    label='แต้มต่อ'
                  >
                    <Input placeholder='กรอกแต้มต่อ' type='number' />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'is_active']}
                    label='สถานะ'
                    valuePropName='checked'
                  >
                    <Switch></Switch>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'is_open']}
                    label='การเดิมพัน'
                    valuePropName='checked'
                  >
                    <Switch></Switch>
                  </Form.Item>
                  <Button
                    style={{
                      position: 'absolute',
                      bottom: 25,
                      fontSize: '15px',
                      lineHeight: '20px',
                    }}
                    onClick={() => remove(name)}
                  >
                    ลบ
                  </Button>

                  {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  // icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAddModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249', color: 'black' }}
            >
              เพิ่ม
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const editModal = (
    <Modal
      open={editModalOpen}
      onClose={closeEditModal}
      onCancel={closeEditModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div>แก้ไขคู่บอล</div>
      <Row style={{ margin: '20px' }}>
        <Col span={4}>
          {moment(selectedHandicap?.match_start_at).format('HH:mm')}
        </Col>
        <Col span={5} align='right'>
          <div
            style={{
              color:
                selectedHandicap?.team_a === selectedHandicap?.handicap_team
                  ? '#FF3232'
                  : '',
            }}
          >
            {selectedHandicap?.team_a}
          </div>
        </Col>
        <Col span={5} align='right'>
          <div
            style={{
              color:
                selectedHandicap?.team_b === selectedHandicap?.handicap_team
                  ? '#FF3232'
                  : '',
            }}
          >
            {selectedHandicap?.team_b}
          </div>
        </Col>
      </Row>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={editHandicap}
        form={editForm}
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item name='handicap_team' label='ทีมต่อ'>
          <Input placeholder='ทีมต่อ' />
        </Form.Item>
        <Form.Item name='handicap_score' label='แต้มต่อ'>
          <Input placeholder='แต้มต่อ' type='number' />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeEditModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={loading}
              style={{ background: '#F7D249' }}
            >
              แก้ไข
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div>
      {addModal}
      {announceModal}
      {toggleStatusModal}
      {confirmModal}
      {secondConfirmModal}
      {editModal}
      {toggleOpenModal}
      <div style={{ fontSize: '30px' }}>บอลเต็ง</div>
      <RangePicker onChange={onDateChange}></RangePicker>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Search
          onSearch={setSearch}
          placeholder='ค้นหาชื่อทีม'
          style={{ width: '30%' }}
        ></Search>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <Button
            placeholder=''
            style={{
              background: '#2D2D2D',
              color: 'white',
              marginRight: '10px',
            }}
            onClick={showAddModal}
          >
            ประกาศผล
          </Button> */}
          <Button
            placeholder=''
            style={{ background: '#F7D249' }}
            onClick={showAddModal}
          >
            + เพิ่มคู่บอล
          </Button>
        </div>
      </div>
      <Table dataSource={tabledata} columns={columns}></Table>
    </div>
  );
}

export default AdminHandicapPage;
