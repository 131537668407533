import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Form, Layout, Menu, Badge, message } from 'antd';
import { useHistory } from 'react-router';
import logo from '../../assets/images/logo.png';
import bank from '../../assets/icons/bank.svg';
import bill from '../../assets/icons/bill.svg';
import user from '../../assets/icons/user.svg';
import dollar from '../../assets/icons/dollar.svg';
import football from '../../assets/icons/football.svg';
import NotificationSound from '../../assets/sound/notification.mp3';
import './index.css';
import api from '../../api';
import { useDataState } from '../../hooks';
import { DownOutlined, PieChartOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API);

function getPath(key) {
  const list = [
    '/dashboard',
    '/user',
    '/deposit',
    '/withdraw',
    '/bank',
    '/adminhandicap',
    '/adminbill',
    '/adminstep',
    '/manageadmin',
  ];
  return list[key];
}

function LayoutAdmin({ children }) {
  const countData = useDataState();
  const [loading, setLoading] = useState();
  const [togglenoti, setToggleNoti] = useState(false);

  const audioPlayer = useRef(null);
  const history = useHistory();
  const useradmin = localStorage.getItem('admin');
  const role = localStorage.getItem('role');

  function playAudio() {
    audioPlayer.current.play();
  }

  const userData = useDataState();

  useEffect(() => {
    const promise = api.admin.getAdminDetail({ username: useradmin });
    userData.load(Promise.resolve(promise).then((value) => value));
  }, [loading]);

  useEffect(() => {
    const promise = api.admin.getTransactionCount();
    countData.load(Promise.resolve(promise).then((value) => value));
  }, [loading, togglenoti]);

  useEffect(() => {
    socket.on('notification', async (data) => {
      setToggleNoti(!togglenoti);
      console.log('Received notification:', data);
      // do something with the notification data
      if (data.type === 'soundAlert') {
        playAudio();
      }
    });
  });

  const depositCount = countData?.data?.data?.depositCount;
  const withdrawCount = countData?.data?.data?.withdrawCount;

  const admin = userData?.data?.data;

  function getItem(label, key, icon, path, children, type) {
    if (role === 'super admin') {
      return {
        key,
        icon,
        children,
        label,
        type,
        path,
      };
    } else if (
      role === 'admin' &&
      (key === '2' || key === '3' || key === '4' || key === '8')
    ) {
      return;
    } else if (
      role === 'admin ฝาก' &&
      (key === '3' || key === '4' || key === '8')
    ) {
      return;
    } else if (
      role === 'admin ถอน' &&
      (key === '2' || key === '4' || key === '8')
    ) {
      return;
    } else if (
      role === 'admin ball' &&
      (key === '1' || key === '2' || key === '3' || key === '4' || key === '8')
    ) {
      return;
    } else {
      return {
        key,
        icon,
        children,
        label,
        type,
        path,
      };
    }
  }

  const items = [
    getItem('ภาพรวม', '0', <PieChartOutlined />, '/dashboard'),
    getItem('บิลทั้งหมด', '6', <img src={bill}></img>, '/adminbill'),
    getItem('บอลเต็ง', '5', <img src={football}></img>, '/adminhandicap'),
    getItem('บอลสเต็ป', '7', <img src={football}></img>, '/adminstep'),
    getItem('สมาชิก', '1', <img src={user}></img>, '/user'),
    getItem(
      <div>
        รายการฝาก
        <Badge
          style={{ marginLeft: '20px' }}
          count={depositCount}
          showZero
          color='#faad14'
        />
      </div>,
      '2',
      <img src={dollar}></img>,
      '/deposit'
    ),
    getItem(
      <div>
        รายการถอน
        <Badge
          style={{ marginLeft: '20px' }}
          count={withdrawCount}
          showZero
          color='#faad14'
        />
      </div>,
      '3',
      <img src={dollar}></img>,
      '/withdraw'
    ),
    getItem('จัดการธนาคาร', '4', <img src={bank}></img>, '/bank'),
  ];

  if (!admin?.cannot_access_admin) {
    items.push(
      getItem('จัดการแอดมิน', '8', <img src={user}></img>, '/manageadmin')
    );
  }

  const [selectedKey, setSelectedKey] = useState(0);

  const onLogout = () => {
    localStorage.clear();
    history.push('/admin');
  };

  const dropdown = [
    {
      key: '1',
      label: <div onClick={onLogout}>ออกจากระบบ</div>,
    },
  ];

  const onClick = (e) => {
    setLoading(true);
    setSelectedKey(e.key);
    history.push(getPath(e.key));
    setLoading(false);
  };

  return (
    <body style={{ paddingTop: '10px' }}>
      <audio ref={audioPlayer} src={NotificationSound} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        <img src={logo} style={{ width: '200px' }}></img>
        <Dropdown
          menu={{
            items: dropdown,
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {useradmin}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </div>
      <div style={{ display: 'flex' }}>
        <Menu
          onClick={onClick}
          style={{ width: 256, height: '100%', background: '#FAFAFA' }}
          selectedKeys={selectedKey}
          mode='inline'
          items={items}
        />
        <div className='page-content'>{children}</div>
      </div>
    </body>
  );
}

export default LayoutAdmin;
