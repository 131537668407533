import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LayoutAdmin from './components/LayoutAdmin';

const AdminRoute = ({
  component: Component,
  componentKey,
  render,
  ...rest
}) => {
  const auth = localStorage.getItem("adminToken");

  return (
    <Route
      {...rest}
      render={props => {
        if (!auth) {
          return <Redirect to={'/admin'} />;
        }

        if (componentKey !== undefined && componentKey !== null) {
          props.key =
            typeof componentKey === 'function'
              ? componentKey(props)
              : componentKey;
        }

        return (
          <LayoutAdmin>
            {Component ? (
              <Component {...props} />
            ) : render ? (
              render(props)
            ) : null}
          </LayoutAdmin>
        );
      }}
    />
  );
};

export default AdminRoute;
