export const getShowHandicapScore = (value) => {
  switch (value) {
    case 0:
      return '0';
    case 0.25:
      return '0-0.5';
    case 0.5:
      return '0.5';
    case 0.75:
      return '0.5-1';
    case 1:
      return '1';
    case 1.25:
      return '1-1.5';
    case 1.5:
      return '1.5';
    case 1.75:
      return '1.5-2';
    case 2:
      return '2';
    case 2.25:
      return '2-2.5';
    case 2.5:
      return '2.5';
    case 2.75:
      return '2.5-3';
    case 3:
      return '3';
    default:
      return value;
  }
};
