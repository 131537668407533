import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  message,
  Select,
  Avatar,
  Card,
  Row,
  Col,
  Progress,
  DatePicker,
  Switch,
} from 'antd';
import { useDataState, useTable } from '../../hooks';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import moment from 'moment';
import kbank from '../../assets/bank/kbank.svg';
import lock from '../../assets/icons/lock.svg';
import unlock from '../../assets/icons/unlock.svg';
import edit from '../../assets/icons/edit.svg';
import key from '../../assets/icons/key.svg';
import { getNumberDisplayWithCommaNFloating } from '../../utils/string';
import { BANKOPTIONS, getBanklogo } from '../../constants/bank';
import { useForm } from 'antd/es/form/Form';
import transactionIcon from '../../assets/icons/transaction.svg';

const { RangePicker } = DatePicker;
const { Search } = Input;

function AdminAdminPage() {
  useEffect(() => {}, []);

  const bankOptions = BANKOPTIONS;

  const history = useHistory();
  const admin = localStorage.getItem('admin');

  const [form] = useForm();
  const [editForm] = useForm();
  const [changePasswordForm] = useForm();

  const [loading, setLoading] = useState();
  const [dateRange, setDateRange] = useState();
  const [search, setSearch] = useState();
  const [addModalOpen, setAddModalOpen] = useState();
  const [editModalOpen, setEditModalOpen] = useState();
  const [lockModalOpen, setLockModalOpen] = useState();
  const [unlockModalOpen, setUnlockModalOpen] = useState();
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState();
  const [selectedAdmin, setSelectedAdmin] = useState();

  const userData = useDataState();

  const { tableProps } = useTable(() => {
    return api.admin.getAdminList({
      keyword: search,
    });
  }, [loading, search]);

  const showAddModal = (value) => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const showLockModal = (user) => {
    setSelectedAdmin(user);
    setLockModalOpen(true);
  };

  const closeLockModal = () => {
    setLockModalOpen(false);
    setSelectedAdmin();
  };

  const showUnlockModal = (user) => {
    setSelectedAdmin(user);
    setUnlockModalOpen(true);
  };

  const closeUnlockModal = () => {
    setUnlockModalOpen(false);
    setSelectedAdmin();
  };

  const showChangePasswordModal = (user) => {
    setSelectedAdmin(user);
    setChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
    setSelectedAdmin();
  };

  const showEditModal = async (user) => {
    setSelectedAdmin(user);
    setEditModalOpen(true);
    editForm.setFieldValue(user);
  };

  const closeEditModal = () => {
    setSelectedAdmin();
    editForm.resetFields();
    setEditModalOpen(false);
  };

  const onAddAdmin = async (value) => {
    setLoading(true);
    await api.admin
      .addAdmin(value)
      .then((res) => {
        message.success('เพิ่ม Admin สำเร็จ');
        closeAddModal();
        form.resetFields();
      })
      .catch((err) => {
        message.error('เกิดข้อผิดพลาด');
      });
    setLoading(false);
  };

  const lockAdmin = async (value) => {
    setLoading(true);
    await api.admin
      .lockAdmin(selectedAdmin._id)
      .then((res) => {
        message.success('ระงับผู้ใช้งานสำเร็จ');
        closeLockModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const unlockAdmin = async () => {
    setLoading(true);
    await api.admin
      .unlockAdmin(selectedAdmin._id)
      .then((res) => {
        message.success('คืนสถานะผู้ใช้งานสำเร็จ');
        closeUnlockModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const changePassword = async (value) => {
    setLoading(true);
    await api.admin
      .changePasswordAdmin({ password: value.password }, selectedAdmin._id)
      .then((res) => {
        message.success('เปลี่ยนรหัสผ่านสำเร็จ');
        setSelectedAdmin();
        changePasswordForm.resetFields();
        closeChangePasswordModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const changeRole = async (value) => {
    setLoading(true);
    await api.admin
      .editAdmin(value, selectedAdmin._id)
      .then((res) => {
        message.success('แก้ไขแอดมินสำเร็จ');
        setSelectedAdmin();
        editForm.resetFields();
        closeEditModal();
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  useEffect(() => {
    if (selectedAdmin) {
      editForm.setFieldsValue(selectedAdmin);
    }
  }, [selectedAdmin, editForm]);

  const tabledata = tableProps?.dataSource?.data;

  const columns = [
    {
      title: 'รหัสผู้ใช้งาน',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.length - b.username.length,
    },
    {
      title: 'role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'วันเวลาที่สมัคร',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (data, row, index) => {
        moment(data).format('DD/MM/YYYY HH:mm');
        return moment(data).format('DD/MM/YYYY HH:mm');
      },
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: 'ปรับยอดเดิมพัน',
      dataIndex: 'can_edit_bet',
      key: 'can_edit_bet',
      render: (_, row, index) => {
        return row.can_edit_bet ? (
          <div style={{ color: '#06C755' }}>เปิด</div>
        ) : (
          <div style={{ color: '#FF3232' }}>ปิด</div>
        );
      },
    },
    {
      title: 'ยกเลิกการเข้าถึงข้อมูลแอดมิน',
      dataIndex: 'cannot_access_admin',
      key: 'cannot_access_admin',
      render: (data, row, index) => {
        return row.cannot_access_admin ? (
          <div style={{ color: '#06C755' }}>เปิด</div>
        ) : (
          <div style={{ color: '#FF3232' }}>ปิด</div>
        );
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'isLocked',
      key: 'isLocked',
      render: (_, row, index) => {
        return row.isLocked ? (
          <div style={{ color: '#FF3232' }}>ระงับการใช้งาน</div>
        ) : (
          <div style={{ color: '#06C755' }}>ปกติ</div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            <img onClick={() => showEditModal(row)} src={edit}></img>
            <img onClick={() => showChangePasswordModal(row)} src={key}></img>
            {row.isLocked ? (
              <img onClick={() => showUnlockModal(row)} src={unlock}></img>
            ) : (
              <img onClick={() => showLockModal(row)} src={lock}></img>
            )}
          </div>
        );
      },
    },
  ];

  const lockModal = (
    <Modal
      open={lockModalOpen}
      onClose={closeLockModal}
      onCancel={closeLockModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ระงับการใช้งาน</div>
      {selectedAdmin && (
        <React.Fragment>
          <div>{`${selectedAdmin.username}`}</div>
        </React.Fragment>
      )}
      <div style={{ fontSize: '20px' }}>คุณต้องการระงับการใช้งานหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={lockAdmin}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeLockModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#CE0E02' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const unlockModal = (
    <Modal
      open={unlockModalOpen}
      onClose={closeUnlockModal}
      onCancel={closeUnlockModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>คืนสถานะผู้ใช้งาน</div>
      {selectedAdmin && (
        <React.Fragment>
          <div>{`${selectedAdmin.username}`}</div>
        </React.Fragment>
      )}
      <div style={{ fontSize: '20px' }}>คุณต้องการคืนสถานะผู้ใช้งานหรือไม่</div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={unlockAdmin}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeLockModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#27AA85' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const changePasswordModal = (
    <Modal
      open={changePasswordModalOpen}
      onClose={closeChangePasswordModal}
      onCancel={closeChangePasswordModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>เปลี่ยนรหัสผ่าน</div>
      {selectedAdmin && (
        <React.Fragment>
          <div>{`${selectedAdmin.username}`}</div>
        </React.Fragment>
      )}
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={changePassword}
        layout='vertical'
        form={changePasswordForm}
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item name='password' required>
          <Input placeholder='รหัสผ่าน' />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeChangePasswordModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249' }}
            >
              เปลี่ยนรหัสผ่าน
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const editModal = (
    <Modal
      open={editModalOpen}
      onClose={closeEditModal}
      onCancel={closeEditModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>แก้ไข Admin</div>
      {selectedAdmin && (
        <React.Fragment>
          <div>{`${selectedAdmin.username}`}</div>
        </React.Fragment>
      )}
      <Form
        initialValues={selectedAdmin}
        form={editForm}
        onFinish={changeRole}
        style={{ fontFamily: 'Kanit' }}
      >
        <Form.Item
          label='Role'
          name='role'
          required
          style={{ width: '48%' }}
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Select
            placeholder='เลือก role'
            options={[
              { value: 'super admin', label: 'super admin' },
              { value: 'admin', label: 'admin' },
              { value: 'admin ball', label: 'admin ball' },
              { value: 'admin ฝาก', label: 'admin ฝาก' },
              { value: 'admin ถอน', label: 'admin ถอน' },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item
          label='ปรับยอดเดิมพัน'
          name='can_edit_bet'
          required
          valuePropName='checked'
          style={{ width: '48%' }}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label='ยกเลิกการเข้าถึงข้อมูลแอดมิน'
          name='cannot_access_admin'
          required
          valuePropName='checked'
          style={{ width: '48%' }}
        >
          <Switch />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeEditModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249' }}
            >
              แก้ไข
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const addModal = (
    <Modal
      open={addModalOpen}
      onClose={closeAddModal}
      onCancel={closeAddModal}
      style={{ fontFamily: 'Kanit', width: '500px' }}
      width='1000px'
      footer={null}
    >
      <div style={{ fontSize: '30px' }}>เพิ่ม Admin</div>
      <Form
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onAddAdmin}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%', fontSize: '20px' }}>
            สร้างบัญชีผู้ใช้งาน
          </div>
          <Form.Item
            label='ชื่อผู้ใช้งาน'
            name='username'
            required
            style={{ width: '48%' }}
            rules={[{ required: true, message: 'Missing value' }]}
          >
            <Input placeholder='ชื่อผู้ใช้งาน' />
          </Form.Item>
          <Form.Item
            label='รหัสผ่าน'
            name='password'
            required
            style={{ width: '48%' }}
            rules={[{ required: true, message: 'Missing value' }]}
          >
            <Input placeholder='รหัสผ่าน' />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item
            label='Role'
            name='role'
            required
            style={{ width: '48%' }}
            rules={[{ required: true, message: 'Missing value' }]}
          >
            <Select
              placeholder='เลือก role'
              options={[
                { value: 'super admin', label: 'super admin' },
                { value: 'admin', label: 'admin' },
                { value: 'admin ball', label: 'admin ball' },
                { value: 'admin ฝาก', label: 'admin ฝาก' },
                { value: 'admin ถอน', label: 'admin ถอน' },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            label='ปรับยอดเดิมพัน'
            name='can_edit_bet'
            required
            valuePropName='checked'
            style={{ width: '24%' }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label='ยกเลิกการเข้าถึงข้อมูลแอดมิน'
            name='cannot_access_admin'
            required
            valuePropName='checked'
            style={{ width: '24%' }}
          >
            <Switch />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeAddModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              style={{ background: '#F7D249', color: 'black' }}
            >
              เพิ่ม
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  return (
    <div>
      {addModal}
      {lockModal}
      {unlockModal}
      {changePasswordModal}
      {editModal}
      <div style={{ fontSize: '30px' }}>Admin</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Search
          onSearch={setSearch}
          placeholder='ค้นหาชื่อAdmin'
          style={{ width: '30%' }}
        ></Search>
        <Button
          placeholder=''
          style={{ background: '#F7D249' }}
          onClick={showAddModal}
        >
          + เพิ่มAdmin
        </Button>
      </div>
      <Table dataSource={tabledata} columns={columns}></Table>
    </div>
  );
}

export default AdminAdminPage;
