import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  message,
  Menu,
  Tag,
  DatePicker,
  Avatar,
} from 'antd';
import { useTable } from '../../hooks';
import { useHistory } from 'react-router';
import './index.css';
import api from '../../api';
import moment from 'moment';
import kbank from '../../assets/bank/kbank.svg';
import uncheck from '../../assets/icons/uncheck.svg';
import check from '../../assets/icons/check.svg';
import { getNumberDisplayWithCommaNFloating } from '../../utils/string';
import { getBanklogo } from '../../constants/bank';
import { useForm } from 'antd/es/form/Form';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API);

const { RangePicker } = DatePicker;

const { Search } = Input;

function getItem(label, key, path, children, type) {
  return {
    key,
    children,
    label,
    type,
    path,
  };
}

function AdminDepositPage() {
  useEffect(() => {}, []);

  const history = useHistory();
  const admin = localStorage.getItem('admin');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState('รอดำเนินการ');
  const [dateRange, setDateRange] = useState();
  const [checkModalOpen, setCheckModalOpen] = useState();
  const [uncheckModalOpen, setUncheckModalOpen] = useState();
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [togglenoti, setToggleNoti] = useState(false);

  const { tableProps } = useTable(() => {
    return api.admin.getTransactionList({
      keyword: search,
      status: status,
      type: 'deposit',
      dateRange: dateRange,
    });
  }, [search, status, dateRange, loading, togglenoti]);

  useEffect(() => {
    socket.on('notification', async (data) => {
      setToggleNoti(!togglenoti);
    });  
  });

  const onDateChange = (value) => {
    setDateRange(value);
  };

  const showCheckModal = (value) => {
    setSelectedTransaction(value);
    setCheckModalOpen(true);
  };

  const closeCheckModal = () => {
    setCheckModalOpen(false);
  };

  const showUncheckModal = (value) => {
    setSelectedTransaction(value);
    setUncheckModalOpen(true);
  };

  const closeUncheckModal = () => {
    setUncheckModalOpen(false);
  };

  const confirmDeposit = async () => {
    setLoading(true);
    const payload = {
      username: selectedTransaction.username,
      status: 'สำเร็จ',
      credits: selectedTransaction.credits,
      update_by: admin,
      type: selectedTransaction.type,
      web_bank_no: selectedTransaction.web_bank_no,
    };
    await api.admin
      .editTransaction(payload, selectedTransaction._id)
      .then((res) => {
        message.success('ทำรายการฝากสำเร็จ');
        closeCheckModal();
        setSelectedTransaction();
        setLoading(false);
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
  };

  const cancelDeposit = async () => {
    setLoading(true);
    const payload = {
      username: selectedTransaction.username,
      status: 'ไม่สำเร็จ',
      credits: 0,
      update_by: admin,
      type: selectedTransaction.type,
      web_bank_no: selectedTransaction.bank_no,
    };
    await api.admin
      .editTransaction(payload, selectedTransaction._id)
      .then((res) => {
        message.success('ปฏิเสธการทำรายการฝากสำเร็จ');
        closeUncheckModal();
        setSelectedTransaction();
        setLoading(false);
      })
      .catch((err) => message.error('เกิดข้อผิดพลาด'));
    setLoading(false);
  };

  const onSelectMenu = (value) => {
    const { key } = value;
    setStatus(key);
  };

  const tabledata = tableProps?.dataSource?.data;

  const columns = [
    {
      title: 'วันที่และเวลา',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (data, row, index) => {
        moment(data).format('DD/MM/YYYY HH:mm');
        return moment(data).format('DD/MM/YYYY HH:mm');
      },
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: 'รหัสผู้ใช้งาน',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.length - b.username.length,
    },
    {
      title: 'ชื่อจริง นามสกุล',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (_, row, index) => {
        return row ? row.first_name + ' ' + row.last_name : '-';
      },
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'จากบัญชี',
      dataIndex: 'bank_no',
      key: 'bank_no',
      render: (data, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            <Avatar src={getBanklogo(row.bank)} size={45}></Avatar>
            <p>{data}</p>
          </div>
        );
      },
    },
    {
      title: 'ฝากเข้าบัญชี',
      dataIndex: 'web_bank_no',
      key: 'web_bank_no',
      render: (data, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            <Avatar src={getBanklogo(row.web_bank)} size={45}></Avatar>
            <p>{data}</p>
          </div>
        );
      },
    },
    {
      title: 'ยอดเงิน',
      dataIndex: 'credits',
      key: 'credits',
      render: (data, row, index) => {
        return getNumberDisplayWithCommaNFloating(data);
      },
      sorter: (a, b) => a.credits - b.credits,
    },
    {
      title: 'ประเภทการฝาก',
      dataIndex: 'deposit_type',
      key: 'deposit_type',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (data, row, index) => {
        const color =
          data === 'สำเร็จ'
            ? 'success'
            : data === 'ไม่สำเร็จ'
            ? 'error'
            : 'processing';
        return <Tag color={color}>{data}</Tag>;
      },
    },
    {
      title: 'ตัวเลือก',
      dataIndex: '',
      key: 'options',
      render: (data, row, index) => {
        return (
          <div style={{ display: 'flex' }}>
            <img onClick={() => showCheckModal(row)} src={check}></img>
            <img onClick={() => showUncheckModal(row)} src={uncheck}></img>
          </div>
        );
      },
    },
    {
      title: 'ทำรายการโดย',
      dataIndex: 'update_by',
      key: 'update_by',
    },
  ].filter((e) => {
    return (
      (e.key !== 'options' || status === 'รอดำเนินการ') &&
      (e.key !== 'update_by' || status !== 'รอดำเนินการ')
    );
  });

  const checkModal = (
    <Modal
      open={checkModalOpen}
      onClose={closeCheckModal}
      onCancel={closeCheckModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ยืนยันการทำรายการฝาก</div>
      <React.Fragment>
        <div>{`${selectedTransaction?.username}`}</div>
        <div>{`${selectedTransaction?.first_name} ${selectedTransaction?.last_name}`}</div>
        <div>{`${selectedTransaction?.bank} ${selectedTransaction?.bank_no}`}</div>
        <div>{`เครดิต: ${selectedTransaction?.credits}`}</div>
      </React.Fragment>
      <div style={{ fontSize: '18px' }}>
        คุณต้องการยืนยันการทำรายการฝากหรือไม่
      </div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={confirmDeposit}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeCheckModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              disabled={loading}
              htmlType='submit'
              style={{ background: '#27AA85' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const unCheckModal = (
    <Modal
      open={uncheckModalOpen}
      onClose={closeUncheckModal}
      onCancel={closeUncheckModal}
      style={{ fontFamily: 'Kanit', width: '200px' }}
      footer={null}
    >
      <div style={{ fontSize: '20px' }}>ปฏิเสธการทำรายการฝาก</div>
      <React.Fragment>
        <div>{`${selectedTransaction?.username}`}</div>
        <div>{`${selectedTransaction?.first_name} ${selectedTransaction?.last_name}`}</div>
        <div>{`${selectedTransaction?.bank} ${selectedTransaction?.bank_no}`}</div>
        <div>{`เครดิต: ${selectedTransaction?.credits}`}</div>
      </React.Fragment>
      <div style={{ fontSize: '18px' }}>
        คุณต้องการปฏิเสธการทำรายการฝากหรือไม่
      </div>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={cancelDeposit}
        layout='vertical'
        style={{ fontFamily: 'Kanit' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item>
            <Button onClick={closeCheckModal}>ยกเลิก</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              disabled={loading}
              htmlType='submit'
              style={{ background: '#CE0E02' }}
            >
              ตกลง
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

  const menuItems = [
    getItem('รอดำเนินการ', 'รอดำเนินการ'),
    getItem('รายการสำเร็จ', 'สำเร็จ'),
    getItem('รายการไม่สำเร็จ', 'ไม่สำเร็จ'),
  ];

  return (
    <div>
      {checkModal}
      {unCheckModal}
      <div style={{ fontSize: '30px' }}>รายการฝาก</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Search
          onSearch={setSearch}
          placeholder='ค้นหาชื่อสมาชิก, รหัสสมาชิก'
          style={{ width: '30%' }}
        ></Search>
        <RangePicker onChange={onDateChange}></RangePicker>
      </div>
      <Menu
        mode='horizontal'
        selectedKeys={[status]}
        items={menuItems}
        onSelect={onSelectMenu}
      ></Menu>
      <Table dataSource={tabledata} columns={columns}></Table>
    </div>
  );
}

export default AdminDepositPage;
